import "./Home.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { GiBeehive } from "react-icons/gi";
import { MdHive } from "react-icons/md";
import { LuMessageSquare } from "react-icons/lu";
import { FaRocket, FaRegUser } from "react-icons/fa";
import { IoStatsChartOutline } from "react-icons/io5";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Profile from "../Profile/Profile";
import ProfileUser from "../ProfileUser/ProfileUser";
import Messaging from "../Messaging/Messaging";
import CreateProject from "../../Components/CreateProject/CreateProject";
import ProjectList from "../Projects/Projects";
import Publications from "../Publications/Publications";
import Project from "../Project/Project";
import Minibar from "../Minibar/Minibar";
import FillProfil from "../../Components/FillProfil/FillProfil";
import { useDispatch, useSelector } from "react-redux";
import { profil } from "../../redux/userSlice";
import Setting from "../Settings/Settings";
import Settings from "../Settings/Settings";
import CreatePost from "../../Components/CreatePost/CreatePost";


const Home = () => {
	const [msgOpen, setMsgOpen] = React.useState(false);
	const user = useSelector((state) => state.user.user.user);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();


	const getActiveClass = (path) => {
		return location.pathname === path ? "active" : "";
	};

	if (!user) {
		dispatch(profil());
		return <div>Chargement des informations...</div>;
	}

	return (
		<div className={`home-container`}>
			<div className={`home-sidebar`}>
				<img src={logo} alt="Logo" className="sidebar-logo big-logo"
					onClick={() => navigate("/home")}
				/>
				<div className="sidebar-menu">
					<MdHive
						className={`sidebar-logo-menu ${getActiveClass("/home")}`}
						onClick={() => navigate("/home")}
					/>
					<IoStatsChartOutline
						className={`sidebar-logo-menu ${getActiveClass("/home/dashboard")}`}
						onClick={() => navigate("/home/dashboard")}
					/>
					<FaRegUser
						className={`sidebar-logo-menu ${getActiveClass("/home/profile")}`}
						onClick={() => navigate("/home/profile")}
					/>
					<LuMessageSquare
						className={`sidebar-logo-menu ${getActiveClass("/home/messaging")}`}
						onClick={() => {
							setMsgOpen(true);
							navigate("/home/messaging");
						}}
					/>
				</div>
			</div >
			<div className={`home-main-content ${msgOpen ? "msg-open" : ""}`}>
				<div className="home-main-contener-content">
					{user.role === "DEFAULT" ? (
						<FillProfil />
					) : (
						<Routes>
							<Route path="/" element={<Publications />} />
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/messages" element={<Messaging />} />
							<Route path="/profileuser/:id" element={<ProfileUser />} />
							<Route path="/projects" element={<CreateProject />} />
							<Route path="/project" element={<ProjectList />} />
							<Route path="/project/:projectId" element={<Project />} />
							<Route path="/post/:id" element={<Publications />} />
							<Route path="/posts" element={<CreatePost />} />
							<Route path="/settings" element={<Settings />} />
						</Routes>)
					}
				</div>
				<Minibar onEvent={{ msgOpen, setMsgOpen }} />
			</div>
		</div >
	);
};

export default Home;
