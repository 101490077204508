import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<GoogleOAuthProvider clientId="256426583838-7i3k39uumbkddn46d8pdavq9ve9sduok.apps.googleusercontent.com">
		<Provider store={store}>
			<App />
		</Provider>
	</GoogleOAuthProvider>
);

// if ('serviceWorker' in navigator) {
// 	window.addEventListener('load', () => {
// 		navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/serviceWorker.js`)
// 			.then((registration) => {
// 				console.log('Service Worker enregistré avec succès:', registration);
// 			})
// 			.catch((error) => {
// 				console.error('Erreur lors de l\'enregistrement du Service Worker:', error);
// 			});
// 	});
// }
reportWebVitals();
