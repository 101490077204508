// WebSocketComponent.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectWebSocket, disconnectWebSocket } from "../../redux/webSocketActions";

const WebSocketComponent = () => {
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);

	useEffect(() => {
		if (token) {
			dispatch(connectWebSocket());
		}

		return () => {
			dispatch(disconnectWebSocket());
		};
	}, [token, dispatch]);

	return <div></div>;
};

export default WebSocketComponent;
