import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Chart from "./chart";
import { getChartOptions } from "./chartOptions";

const PieChart = ({
	selectedPublication,
	dataTypePie,
	pieChartData,
	modalOpenPie,
	toggleModalPie,
	handleDataTypeChangePie,
	handlePublicationChange,
	stats,
}) => {
	return (
		<div className="box half">
			<div className="chart-header filtre-left">
				<div className="filtre-title-pie">
					<BiDotsHorizontalRounded onClick={toggleModalPie} />
					<h2>
						Camembert des {dataTypePie === "views" ? "Vues" : "Likes"}
					</h2>
				</div>
				<div className="select-post">
					<select
						value={selectedPublication?.titre || ""}
						onChange={handlePublicationChange}
					>
						{stats.map((stat) => (
							<option key={stat.titre} value={stat.titre}>
								{stat.titre}
							</option>
						))}
					</select>
				</div>
			</div>
			<Chart
				options={getChartOptions(
					selectedPublication ? selectedPublication.titre : "",
					[],
					pieChartData,
					"pie"
				)}
			/>
			{modalOpenPie && (
				<div className="modal-filter-pie modal-filter-left">
					<div className="modal-content-filter-pie">
						<button
							onClick={() => handleDataTypeChangePie("views")}
							className={dataTypePie === "views" ? "active" : ""}
						>
							Voir les vues
						</button>
						<button
							onClick={() => handleDataTypeChangePie("likes")}
							className={dataTypePie === "likes" ? "active" : ""}
						>
							Voir les likes
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default PieChart;
