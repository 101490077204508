import React, { useState } from 'react';
import './LandingPage.css';
import logo from "../../assets/images/whitelogo.png";
import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/p2.png";
import p3 from "../../assets/images/p3.png";
import p4 from "../../assets/images/p4.png";
import artistImg from "../../assets/images/p5.png";  // Exemple d'image pour artistes
import labelImg from "../../assets/images/p7.png";   // Exemple d'image pour labels
import collaborationImg from "../../assets/images/p6.png";  // Exemple d'image pour collaboration
import Footer from '../../Components/Footer/Footer';

const LandingPage = () => {
	const [activeTarget, setActiveTarget] = useState('');

	return (
		<div className="landing-page">
			{/* Header */}
			<header className="landing-header">
				<img src={logo} className='logo' alt="Logo" />
				<nav>
					<ul>
						<li><a href="#how-it-works">Comment ça marche</a></li>
						<li><a href="#carousel">Que faisons-nous</a></li>
						<li><a href="/contact">Contact</a></li>
						<li><a href="#faq">FAQ</a></li>
						<li><a href="/login">Connexion</a></li>
					</ul>
				</nav>
			</header>

			{/* Hero Section */}
			<section className="landing-hero">
				<div className="hero-content">
					<h1>Crée les connexions qui transforment ta carrière</h1>
					<p>
						Rejoins une plateforme dédiée à la mise en relation des artistes indépendants avec des professionnels de tous horizons — galeries, agences, entreprises créatives, et bien plus encore.
					</p>
					<p>
						Expose ton talent, développe ton réseau, et collabore sur des projets qui te propulsent vers de nouvelles opportunités.
					</p>
					<a href="/signup" className="hero-button">Rejoins-nous aujourd'hui</a>
				</div>
				<div className="hero-image">
					<img src={p1} alt="Artistes créatifs collaborant dans divers domaines" />
				</div>
			</section>

			{/* Banner Section */}
			<section className="landing-banner">
				<h2>Le tremplin pour ta carrière artistique</h2>
				<p>Notre plateforme te connecte avec les bonnes personnes pour faire avancer ta carrière.</p>
			</section>

			{/* How It Works Section */}
			<section id="how-it-works" className="landing-how-it-works">
				<h2>Comment ça marche ?</h2>
				<div className="steps">
					<div className="step">
						<img src={p2} alt="Upload Art" />
						<h3>Partage ton Art</h3>
						<p>Uploade facilement tes créations et rends-les visibles aux professionnels du secteur.</p>
					</div>
					<div className="step">
						<img src={p3} alt="Connect with Producers" />
						<h3>Connecte-toi avec des experts</h3>
						<p>Discute, collabore, et trouve des opportunités qui changent ta carrière.</p>
					</div>
					<div className="step">
						<img src={p4} alt="Achieve Success" />
						<h3>Réussis ensemble</h3>
						<p>Célèbre tes réussites en tant qu'artiste avec le soutien des professionnels du secteur.</p>
					</div>
				</div>
			</section>

			{/* Carousel Section */}
			<section id="carousel" className="landing-carousel">
				<h2>Découvre les créations de nos artistes</h2>
				<div className="carousel">
					<div className="carousel-item">
						<img src="/images/art1.jpg" alt="Art 1" />
					</div>
					<div className="carousel-item">
						<img src="/images/art2.jpg" alt="Art 2" />
					</div>
					<div className="carousel-item">
						<img src="/images/art3.jpg" alt="Art 3" />
					</div>
				</div>
			</section>

			{/* Banner Section */}
			<section className="landing-marketing-banner">
				<div className="target-container">
					<div
						className={`target-item ${activeTarget === 'artists' ? 'active' : ''}`}
						onMouseEnter={() => setActiveTarget('artists')}
						onMouseLeave={() => setActiveTarget('')}
					>
						<img src={artistImg} alt="Artistes" />
						{activeTarget === 'artists' && (
							<div className="target-info">
								<h3>Pour les Artistes</h3>
								<p>Accrois ta notoriété, génère des revenus grâce à la publicité, et collabore avec d'autres artistes. Sois découvert par des galeries et des professionnels créatifs.</p>
							</div>
						)}
					</div>
					<div
						className={`target-item ${activeTarget === 'labels' ? 'active' : ''}`}
						onMouseEnter={() => setActiveTarget('labels')}
						onMouseLeave={() => setActiveTarget('')}
					>
						<img src={labelImg} alt="Labels" />
						{activeTarget === 'labels' && (
							<div className="target-info">
								<h3>Pour les Professionnels</h3>
								<p>Utilise nos outils avancés pour découvrir et sélectionner les talents de demain. Intègre des IA pour faciliter les collaborations et les projets.</p>
							</div>
						)}
					</div>
					<div
						className={`target-item ${activeTarget === 'collaboration' ? 'active' : ''}`}
						onMouseEnter={() => setActiveTarget('collaboration')}
						onMouseLeave={() => setActiveTarget('')}
					>
						<img src={collaborationImg} alt="Collaboration" />
						{activeTarget === 'collaboration' && (
							<div className="target-info">
								<h3>Collaborations</h3>
								<p>Facilite les échanges et les collaborations avec d'autres artistes pour des projets communs dans le domaine créatif.</p>
							</div>
						)}
					</div>
				</div>
			</section>

			{/* FAQ Section */}
			<section id="faq" className="landing-faq">
				<h2>Questions fréquentes</h2>
				<div className="faq-item">
					<h3>Comment puis-je m'inscrire ?</h3>
					<p>Il te suffit de cliquer sur le bouton "Rejoins-nous" et de créer ton compte gratuitement.</p>
				</div>
				<div className="faq-item">
					<h3>Est-ce que c'est gratuit ?</h3>
					<p>Oui, la plateforme est entièrement gratuite pour les artistes. Les professionnels peuvent également rejoindre gratuitement.</p>
				</div>
				<div className="faq-item">
					<h3>Quels types d'art sont acceptés ?</h3>
					<p>Nous acceptons tous les types d'art, y compris la musique, les arts visuels, la danse, et bien plus encore.</p>
				</div>
			</section>

			{/* Footer */}
			<Footer />
		</div>
	);
};

export default LandingPage;
