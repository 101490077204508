import React, { useState } from 'react';
import './FilterSidebar.css';
import { BiChevronsLeft } from "react-icons/bi";
import { BiChevronsRight } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { setTags } from '../../redux/publicationSlice';
import { FaTimes } from 'react-icons/fa';

const FilterSidebar = ({ onFilterChange }) => {
	const category = useSelector((state) => state.publications.feed.filters.categorie);
	const [openFilter, setOpenFilter] = useState(false);
	const dispatch = useDispatch();
	const tags = useSelector((state) => state.publications.feed.filters.tags);


	const handleTag = (e) => {
		console.log(e.target.value);
		dispatch(setTags(e.target.value));
	}


	return (
		<div className={`filter-sidebar-container ${openFilter ? 'open' : 'closed'}`}>
			<button className={`filter-sidebar-toggle ${openFilter ? 'open' : 'closed'}`} onClick={() => setOpenFilter(!openFilter)}>
				{openFilter ? <BiChevronsLeft className='icon-close' /> : <BiChevronsRight className='icon-close' />}
			</button>

			{/* Contenu de la sidebar */}
			<div className="filter-sidebar-content">
				<h2>Filtres</h2>

				{/* Ordering */}
				<div className="filter-sidebar-section">
					<h4>Order by</h4>
					<div className="filter-sidebar-options">
						<button value="date" onClick={onFilterChange}>Date</button>
						<button value="views" onClick={onFilterChange}>Popularité</button>
					</div>
				</div>


				{/* <div className="filter-sidebar-section">
					<h4>Filter by Category</h4>
					<div className="filter-sidebar-options">
						{['Action', 'Animal', 'Assets', 'Background', 'Character', 'Clothing', 'Concept'].map((category) => (
							<label key={category}>
								<input type="checkbox" value={category} onChange={onFilterChange} />
								{category}
							</label>
						))}
					</div>
				</div> */}

				{
					category !== 'user' ? (
						<div className="filter-sidebar-section">
							<h4>Users</h4>
							<input type="text" placeholder="Search Users" onChange={onFilterChange} />
						</div>
					) : null
				}



				<div className="filter-sidebar-section">
					<h4>Tags</h4>
					<input
						type="text"
						placeholder="Search Tags"
						// Utilise onKeyDown au lieu de onKeyPress
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault(); // Empêche le comportement par défaut du formulaire, si nécessaire
								handleTag(e); // Appelle la fonction handleTag
								e.target.value = ''; // Efface la valeur de l'input
							}
						}}
					/>
					<div className="button-tags">
						{
							tags?.map((tag) => (
								<button key={tag} onClick={handleTag} value={tag} className='' >{tag}
									<FaTimes className="clear-tags" />
								</button>
							))
						}
					</div>
				</div>

			</div>
		</div>
	);
};

export default FilterSidebar;
