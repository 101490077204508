import React from "react";
import "./UserCard.css"; // Importe le fichier CSS
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const UserCard = ({ user }) => {
	const navigate = useNavigate();
	return (
		<div className="user-card" onClick={
			() => {
				navigate(`/home/profileuser/${user._id}`);
			}
		}>
			<div className="user-banner">
				<img src={user.banner} alt="User Banner" className="banner-image" />
			</div>
			<div className="user-info">
				<div className="user-avatar">
					<img src={user.photo_profil} alt={user.username} className="avatar-image" />
				</div>
				<div className="user-details">
					<h2 className="user-username">{user.username}</h2>
					<p className="user-role">{
						user.jobTitle.charAt(0).toUpperCase() + user.jobTitle.slice(1)
					}</p>
					<p className="user-email">{user.email}</p>
					<p className="user-subscription">Plan: {user.subscription}</p>
				</div>
			</div>
		</div>
	);
};

export default UserCard;
