import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, updatePassword, updateUserProfile } from "../../redux/userSlice"; // Exemple d'actions Redux
import "./Settings.css";
import { FaUser, FaEnvelope, FaLock, FaFileAlt, FaQuestionCircle } from "react-icons/fa";

const Settings = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user);

	const [profileForm, setProfileForm] = useState({
		username: user.username || "",
		email: user.email || "",
		bio: user.bio || "",
	});

	const [passwordForm, setPasswordForm] = useState({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	const handleProfileChange = (e) => {
		setProfileForm({
			...profileForm,
			[e.target.name]: e.target.value,
		});
	};

	const handlePasswordChange = (e) => {
		setPasswordForm({
			...passwordForm,
			[e.target.name]: e.target.value,
		});
	};

	const handleProfileSubmit = (e) => {
		e.preventDefault();
		dispatch(updateUserProfile(profileForm));
	};

	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		if (passwordForm.newPassword === passwordForm.confirmPassword) {
			// dispatch(updatePassword(passwordForm));
		} else {
			alert("Les mots de passe ne correspondent pas !");
		}
	};

	return (
		<div className="settings-page">
			<h2 className="settings-title">Paramètres</h2>
			<div className="settings-container">
				{/* Section Profil */}
				<div className="settings-section">
					<h3><FaUser /> Gestion du Profil</h3>
					<form className="settings-form" onSubmit={handleProfileSubmit}>
						<div className="form-group">
							<label>Nom d'utilisateur</label>
							<input
								type="text"
								name="username"
								value={profileForm.username}
								onChange={handleProfileChange}
							/>
						</div>
						<div className="form-group">
							<label>Email</label>
							<input
								type="email"
								name="email"
								value={profileForm.email}
								onChange={handleProfileChange}
							/>
						</div>
						<div className="form-group">
							<label>Biographie</label>
							<textarea
								name="bio"
								value={profileForm.bio}
								onChange={handleProfileChange}
							/>
						</div>
						<button type="submit" className="save-btn">Sauvegarder les modifications</button>
					</form>
				</div>

				{/* Section Sécurité (Mot de passe) */}
				<div className="settings-section">
					<h3><FaLock /> Sécurité</h3>
					<form className="settings-form" onSubmit={handlePasswordSubmit}>
						<div className="form-group">
							<label>Mot de passe actuel</label>
							<input
								type="password"
								name="currentPassword"
								value={passwordForm.currentPassword}
								onChange={handlePasswordChange}
							/>
						</div>
						<div className="form-group">
							<label>Nouveau mot de passe</label>
							<input
								type="password"
								name="newPassword"
								value={passwordForm.newPassword}
								onChange={handlePasswordChange}
							/>
						</div>
						<div className="form-group">
							<label>Confirmer le nouveau mot de passe</label>
							<input
								type="password"
								name="confirmPassword"
								value={passwordForm.confirmPassword}
								onChange={handlePasswordChange}
							/>
						</div>
						<button type="submit" className="save-btn">Modifier le mot de passe</button>
					</form>
				</div>

				{/* Section Messagerie */}
				<div className="settings-section">
					<h3><FaEnvelope /> Messagerie</h3>
					<div className="settings-content">
						<p>Gérez vos préférences de notification pour la messagerie privée.</p>
						<form className="settings-form">
							<div className="form-group">
								<label>Recevoir des notifications</label>
								<select>
									<option value="all">Toutes les notifications</option>
									<option value="mentions">Mentions uniquement</option>
									<option value="none">Aucune notification</option>
								</select>
							</div>
							<button type="submit" className="save-btn">Sauvegarder</button>
						</form>
					</div>
				</div>

				{/* Section Publications */}
				<div className="settings-section">
					<h3><FaFileAlt /> Gestion des Publications</h3>
					<div className="settings-content">
						<p>Modifiez et supprimez vos publications.</p>
						{/* Exemple d'un lien vers la page de gestion des publications */}
						<button className="manage-publications-btn" onClick={() => window.location.href = "/publications/manage"}>
							Gérer mes publications
						</button>
					</div>
				</div>

				{/* Section Aide */}
				<div className="settings-section">
					<h3><FaQuestionCircle /> Aide & Support</h3>
					<div className="settings-content">
						<p>Vous avez des questions ou besoin d'aide ?</p>
						<button className="support-btn" onClick={() => window.location.href = "/support"}>
							Contactez le support
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Settings;
