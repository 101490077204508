import React from "react";
import Chart from "./chart";
import { getChartOptions } from "./chartOptions";

const WeeklyStatsChart = ({ daysOfWeek, daySeriesData }) => {
	return (
		<div className="box half">
			<div className="chart-header">
				<h2>Vues et Likes quotidien</h2>
			</div>
			<Chart
				options={getChartOptions(
					"Vues et Likes par Jour de la Semaine",
					daysOfWeek,
					daySeriesData,
					"bar2"
				)}
			/>
		</div>
	);
};

export default WeeklyStatsChart;
