import React, { useState } from "react";
import smiley from "../../assets/images/smiley.svg";
import trash from "../../assets/images/corbeille.svg";
import transfer from "../../assets/images/transfer.svg";
import send from "../../assets/images/send.svg";
import moreOptionsIcon from "../../assets/images/moreoptionsicon.svg";
import { updateMessage } from "../../redux/messagesSlice";
import { useDispatch } from "react-redux";
import { MdEdit } from "react-icons/md";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const MessageModal = ({
  onDelete,
  onClose,
  messageId,
  messageContent,
  onUpdate,
  onEdit,
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(messageContent);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const handleDeleteClick = () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?")) {
      onDelete(messageId);
    }
  };

  const handleEditClick = () => {
    onEdit(messageId, newContent);
  };

  const handleUpdateClick = async () => {
    dispatch(updateMessage({ messageId, content: newContent }));
    onUpdate(messageId, newContent);
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setNewContent(event.target.value);
  };

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const handleEmojiSelect = (emoji) => {
    setNewContent((prevContent) => prevContent + emoji.native);
    setIsEmojiPickerOpen(false);
  };

  return (
    <div className="message-modal">
      <img src={smiley} alt="Smiley" onClick={toggleEmojiPicker} />
      {isEmojiPickerOpen && (
        <div className="picker">
          <Picker
            data={data}
            onEmojiSelect={handleEmojiSelect}
            autoFocus="true"
            previewPosition="none"
            emojiSize={24}
            theme="auto"
          />
        </div>
      )}
      <img src={transfer} alt="Transfer" />
      <MdEdit
        onClick={handleEditClick}
        style={{ color: "green", fontSize: "24px", cursor: "pointer" }}
        title="Edit"
      />
      <img src={moreOptionsIcon} alt="More options" />
      <img src={trash} alt="none" onClick={handleDeleteClick} />
      <span className="close-message-button" onClick={onClose}>
        &times;
      </span>
    </div>
  );
};

export default MessageModal;
