import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MyAxios from "../Services/Interceptor";

export const sendMessage = createAsyncThunk(
	"messages/sendMessage",
	async ({ senderId, conversationId, content }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("messages/envoyer", {
				senderId,
				conversationId,
				content,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchMessages = createAsyncThunk(
	"messages/fetchMessages",
	async (conversationId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(
				`messages/conversation/${conversationId}`
			);
			return { conversationId, messages: response.data };
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchConversations = createAsyncThunk(
	"unique messages/conversations",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`messages/conversations`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchConversation = createAsyncThunk(
	"messages/conversation",
	async (conversationId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(
				`messages/conversation/${conversationId}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchFeed = createAsyncThunk(
	"messages/fetchFeed",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("feed/fil");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const createConversation = createAsyncThunk(
	"messages/createConversation",
	async ({ usersIds, groupName }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("messages/conversations", {
				usersIds,
				groupName,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const deleteConversation = createAsyncThunk(
	"messages/deleteConversation",
	async (conversationId, { rejectWithValue }) => {
		try {
			await MyAxios.delete(`/messages/conversation/${conversationId}`);
			return conversationId;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const deleteMessage = createAsyncThunk(
	"messages/deleteMessage",
	async ({ conversationId, messageId }, { rejectWithValue }) => {
		try {
			await MyAxios.delete(
				`messages/conversation/${conversationId}/message/${messageId}`
			);
			return { conversationId, messageId };
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const updateMessage = createAsyncThunk(
	"messages/updateMessage",
	async ({ messageId, content }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.put(
				`/messages/updatemessage/${messageId}`,
				{ content }
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const messagesSlice = createSlice({
	name: "messages",
	initialState: {
		conversations: [],
		conversationMessages: {},
		currentConversation: null,
		name: "",
		feed: [],
		status: "idle",
		conversationStatus: "idle",
		error: null,
	},
	reducers: {
		MessageReaded: (state, action) => {
			const { conversationId, messageId } = action.payload;
			if (state.conversationMessages[conversationId]) {
				state.conversationMessages[conversationId].messages =
					state.conversationMessages[conversationId].messages.map((message) =>
						message._id === messageId ? { ...message, status: "lu" } : message
					);
			}
		},
		addMessage: (state, action) => {
			const { conversationId, message } = action.payload;
			if (state.conversations.find((c) => c._id === conversationId)) {
				state.conversationMessages[conversationId].messages.push(message);
			}
		},
		setCurrentConversation: (state, action) => {
			state.currentConversation = action.payload;
		},
		toggleCreateConversationModal: (state) => {
			state.isModalOpen = !state.isModalOpen;
		},
		receiveMessage: (state, action) => {
			const newMessage = action.payload;
			const { conversationId } = newMessage;
			if (state.conversationMessages[conversationId]) {
				state.conversationMessages[conversationId].messages.push(newMessage);
			} else {
				state.conversationMessages[conversationId] = {
					messages: [newMessage],
				};
			}
		},
		messageDeleted: (state, action) => {
			const { conversationId, messageId } = action.payload;
			if (state.conversationMessages[conversationId]) {
				state.conversationMessages[conversationId].messages =
					state.conversationMessages[conversationId].messages.map((message) =>
						message._id === messageId
							? { ...message, content: "Message supprimé" }
							: message
					);
			}
		},
		messageUpdated: (state, action) => {
			const { messageId, content } = action.payload;
			const { currentConversation } = state;
			if (state.conversationMessages[currentConversation._id]) {
				state.conversationMessages[currentConversation._id].messages =
					state.conversationMessages[currentConversation._id].messages.map(
						(message) =>
							message._id === messageId ? { ...message, content } : message
					);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(sendMessage.pending, (state) => {
				state.status = "loading";
			})
			.addCase(sendMessage.fulfilled, (state, action) => {
				const newMessage = action.payload;
				const { conversationId } = newMessage;
				if (state.conversationMessages[conversationId]) {
					state.conversationMessages[conversationId].messages.push(newMessage);
				} else {
					state.conversationMessages[conversationId] = {
						messages: [newMessage],
					};
				}
				state.status = "succeeded";
			})
			.addCase(sendMessage.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchConversations.pending, (state) => {
				state.conversationStatus = "loading";
			})
			.addCase(fetchConversations.fulfilled, (state, action) => {
				state.conversations = action.payload;
				state.conversationStatus = "succeeded";
			})
			.addCase(fetchConversations.rejected, (state, action) => {
				state.conversationStatus = "failed";
				state.error = action.payload;
			})
			.addCase(fetchMessages.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchMessages.fulfilled, (state, action) => {
				const { conversationId, messages } = action.payload;
				state.conversationMessages[conversationId] = messages;
				state.status = "succeeded";
			})
			.addCase(fetchMessages.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchFeed.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchFeed.fulfilled, (state, action) => {
				state.feed = action.payload.feed;
				state.status = "succeeded";
			})
			.addCase(fetchFeed.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(createConversation.pending, (state) => {
				state.status = "loading";
			})
			.addCase(createConversation.fulfilled, (state, action) => {
				state.conversations.push(action.payload);
				state.status = "succeeded";
			})
			.addCase(createConversation.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(deleteConversation.pending, (state) => {
				state.status = "loading";
			})
			.addCase(deleteConversation.fulfilled, (state, action) => {
				state.conversations = state.conversations.filter(
					(conversation) => conversation._id !== action.payload
				);
			})
			.addCase(deleteConversation.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(deleteMessage.pending, (state) => {
				state.status = "loading";
			})
			.addCase(deleteMessage.fulfilled, (state, action) => {
				const { conversationId, messageId } = action.payload;
				if (state.conversationMessages[conversationId]) {
					state.conversationMessages[conversationId].messages =
						state.conversationMessages[conversationId].messages.map((message) =>
							message._id === messageId
								? { ...message, content: "Message supprimé" }
								: message
						);
				}
				state.status = "succeeded";
			})
			.addCase(deleteMessage.rejected, (state, action) => {
				state.error = action.payload;
			})
			.addCase(updateMessage.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateMessage.fulfilled, (state, action) => {
				const updatedMessage = action.payload;
				state.conversationMessages[updatedMessage.conversationId].messages =
					state.conversationMessages[
						updatedMessage.conversationId
					].messages.map((message) =>
						message._id === updatedMessage._id ? updatedMessage : message
					);
				state.status = "succeeded";
			})
			.addCase(updateMessage.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			});
	},
});

export const {
	setCurrentConversation,
	messageReceived,
	messageDeleted,
	receiveMessage,
	conversationCreated,
	userAddedToConversation,
	userRemovedFromConversation,
	addMessage,
	MessageReaded,
	toggleCreateConversationModal,
} = messagesSlice.actions;

export default messagesSlice.reducer;
