import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Chart from "./chart";
import { getChartOptions } from "./chartOptions";

const TotalViewsLikesChart = ({
	categories,
	cumulativeSeriesData,
	modalOpenTime,
	toggleModalTime,
	filteredPeriod,
	handleFilterPeriodChange,
}) => {
	return (
		<div className="box wide">
			<div className="chart-header">
				<h2>Vues et Likes Totaux</h2>
				<BiDotsHorizontalRounded onClick={toggleModalTime} />
			</div>
			<Chart
				options={getChartOptions(
					"",
					categories,
					cumulativeSeriesData,
					"line"
				)}
			/>
			{modalOpenTime && (
				<div className={`modal-filter-time ${modalOpenTime ? "show" : ""}`}>
					<div className="modal-content-filter-time">
						<button
							className={filteredPeriod === "all" ? "active" : ""}
							onClick={() => handleFilterPeriodChange("all")}
						>
							Tous les temps
						</button>
						<button
							className={filteredPeriod === "today" ? "active" : ""}
							onClick={() => handleFilterPeriodChange("today")}
						>
							Aujourd'hui
						</button>
						<button
							className={filteredPeriod === "7days" ? "active" : ""}
							onClick={() => handleFilterPeriodChange("7days")}
						>
							Les 7 derniers jours
						</button>
						<button
							className={filteredPeriod === "1month" ? "active" : ""}
							onClick={() => handleFilterPeriodChange("1month")}
						>
							Le dernier mois
						</button>
						<button
							className={filteredPeriod === "3months" ? "active" : ""}
							onClick={() => handleFilterPeriodChange("3months")}
						>
							Les 3 derniers mois
						</button>
						<button
							className={filteredPeriod === "1year" ? "active" : ""}
							onClick={() => handleFilterPeriodChange("1year")}
						>
							Les 12 derniers mois
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default TotalViewsLikesChart;
