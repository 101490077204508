import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Search from "./pages/Search/Search";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import LandingPage from "./pages/LandingPage/LandingPage";
import PrivateRoute from "./Components/Auth/PrivateRoute";
import Subscribe from "./pages/Subscribe/Subscribe";
import ContactPage from "./pages/Contact/ContactPage";

function App() {
	return (
		<Router>
			<div className="App">
				<Routes>
					<Route
						path="/"
						element={
							<LandingPage />
						}
					/>
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/landing" element={<LandingPage />} />
					<Route path="/subscribe" element={<Subscribe />} />
					<Route path="/contact" element={<ContactPage />} />
					<Route
						path="/home/*"
						element={
							<PrivateRoute>
								<Home />
							</PrivateRoute>
						}
					/>
					<Route path="/search" element={<Search />} />
					<Route path="*" element={<h1>404 - Route inconnue</h1>} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
