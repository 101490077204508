// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import projectReducer from "./projectSlice";
import messagesReducer from "./messagesSlice";
import notificationReducer from "./notificationSlice";
import publicationReducer from "./publicationSlice";
import webSocketMiddleware from "./webSocketMiddleware";
import collaborationReducer from "./collaborationSlice";

const store = configureStore({
	reducer: {
		auth: authReducer,
		messages: messagesReducer,
		user: userReducer,
		projects: projectReducer,
		projects: projectReducer,
		publications: publicationReducer,
		notifications: notificationReducer,
		collaborations: collaborationReducer,
	},
	devTools: process.env.NODE_ENV !== "production",
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(webSocketMiddleware),
});

export default store;
