import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Chart from "./chart";
import { getChartOptions } from "./chartOptions";

const DetailedViewsLikesChart = ({
	categories,
	dataTypeBar,
	toggleModalBar,
	modalOpenBar,
	handleDataTypeChangeBar,
	seriesDataViewsDetailed,
	seriesDataLikesDetailed,
}) => {
	return (
		<div className="box wide">
			<div className="chart-header">
				<h2>{dataTypeBar === "views" ? "Vues détaillées" : "Likes détaillés"}</h2>
				<BiDotsHorizontalRounded onClick={toggleModalBar} />
			</div>
			<Chart
				options={getChartOptions(
					"",
					categories,
					dataTypeBar === "views"
						? [
							...seriesDataViewsDetailed.artistes,
							...seriesDataViewsDetailed.producteurs,
							...seriesDataViewsDetailed.spectateurs,
						]
						: [
							...seriesDataLikesDetailed.artistes,
							...seriesDataLikesDetailed.producteurs,
							...seriesDataLikesDetailed.spectateurs,
						],
					"bar"
				)}
			/>
			{modalOpenBar && (
				<div className="modal-filter-bar">
					<div className="modal-content-filter-bar">
						<button
							onClick={() => handleDataTypeChangeBar("views")}
							className={dataTypeBar === "views" ? "active" : ""}
						>
							Voir les vues détaillées
						</button>
						<button
							onClick={() => handleDataTypeChangeBar("likes")}
							className={dataTypeBar === "likes" ? "active" : ""}
						>
							Voir les likes détaillés
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default DetailedViewsLikesChart;
