import React, { useState, useEffect, useRef } from 'react';
import './ChatWindow.css';
import { MdClose, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_MESSAGE, sendMessage } from '../../redux/webSocketActions';
import { FcCheckmark } from "react-icons/fc";
import { IoCheckmarkSharp } from "react-icons/io5";
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { BsEmojiSmile } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';

const ChatWindow = ({ conversationId, userName, userImage, messages, onClose }) => {
	const userId = useSelector((state) => state.user.user._id);
	const [newMessage, setNewMessage] = useState('');
	const [isMinimized, setIsMinimized] = useState(false);
	const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
	const conversation = useSelector((state) => state.messages.conversations.find((c) => c._id === conversationId));
	const dispatch = useDispatch();
	const messagesEndRef = useRef(null);

	// Fonction pour faire défiler vers le bas
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	// Faire défiler vers le bas à chaque changement des messages
	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const handleSendMessage = async () => {
		if (!newMessage || !conversationId) return;

		const messageData = {
			senderId: userId,
			conversationId: conversationId,
			content: newMessage,
			receivers: conversation.users
		};

		try {
			dispatch(sendMessage(SEND_MESSAGE, messageData));
			setNewMessage("");
		} catch (error) {
			console.error("Erreur lors de l'envoi du message:", error);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleSendMessage();
		}
	};

	const toggleMinimize = () => {
		setIsMinimized(!isMinimized);
	};

	const toggleEmojiPicker = () => setIsEmojiPickerOpen(!isEmojiPickerOpen);

	const handleEmojiSelect = (emoji) => {
		setNewMessage((prevMessage) => prevMessage + emoji.native);
	};

	return (
		<div className={`chat-window ${isMinimized ? 'minimized' : ''}`}>
			<div className="chat-header">
				<div className="user-info" onClick={toggleMinimize}>
					<img src={userImage} alt="User" className="user-image" />
					<span className="user-name">{userName}</span>
				</div>
				<div className="chat-controls">
					<button className="minimize-button" onClick={toggleMinimize}>
						{isMinimized ? <MdExpandLess size={25} /> : <MdExpandMore size={25} />}
					</button>
					<button className="window-close-button" onClick={onClose}>
						<MdClose size={20} />
					</button>
				</div>
			</div>

			{!isMinimized && (
				<>
					<div className="chat-body">
						{messages.map((message, index) => {
							if (message.senderId !== userId && message.status == "non lu") {
								dispatch(sendMessage("MESSAGE_READ", { conversationId, messageId: message._id, receivers: conversation.users }));
							}
							return (
								<div
									key={index}
									className={`chat-message ${message.senderId === userId ? 'me' : 'them'}`}
								>
									<div className="message-content">
										{message.content}
										{message.status == "non lu" ? <IoCheckmarkSharp size={20} /> : <FcCheckmark size={20} />}
									</div>
									<div className="message-timestamp">{new Date(message.createdAt).toLocaleTimeString().slice(0, 5)
									}</div>
								</div>
							)
						})}
						<div ref={messagesEndRef} />
					</div>
					<div className="chat-footer">
						<BsEmojiSmile size={25} className="emoji-button" onClick={toggleEmojiPicker} />
						{isEmojiPickerOpen && (
							<div className="emoji-picker">
								<Picker data={data} onEmojiSelect={handleEmojiSelect} previewPosition="none" emojiSize={24} theme="auto" />
							</div>
						)}
						<input
							type="text"
							className="chat-input"
							placeholder="Tapez un message..."
							value={newMessage}
							onChange={(e) => setNewMessage(e.target.value)}
							onKeyDown={handleKeyDown}
						/>
						<button className="send-button" onClick={handleSendMessage}>
							<FiSend size={20} />
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default ChatWindow;
