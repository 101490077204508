import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { refresh } from "../../redux/authSlice";
import { logout } from "../../redux/authSlice";

const PrivateRoute = ({ children }) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const dispatch = useDispatch();
	const status = useSelector((state) => state.user.userStatus);
	const navigate = useNavigate();
	const token = localStorage.getItem("token");

	useEffect(() => {
		if (!isAuthenticated) {
			dispatch(refresh(token)).then((result) => {
				if (status === "rejected") {
					navigate("/login");
				}
			});
		}
	});

	if (!token || !isAuthenticated) {
		navigate("/login");
	}
	return children;
};

export default PrivateRoute;
