import { type } from "@testing-library/user-event/dist/type";

export const getChartOptions = (
  title,
  categories,
  seriesData,
  typeDeSchema = "pie",
  chartType = "bar"
) => {
  const colors = {
    Artistes: "dodgerblue",
    Producteurs: "crimson",
    Spectateurs: "orange",
  };

  const getCumulativeTotal = (data) =>
    data.reduce((acc, value) => acc + value, 0);

  const legendData =
    typeDeSchema === "bar"
      ? ["Artistes", "Producteurs", "Spectateurs"]
      : seriesData.map((series) => series.name);

  const filteredCategories = categories.filter((category, index) =>
    seriesData.some((series) => series.values[index] > 0)
  );

  switch (typeDeSchema) {
    case "bar":
      return {
        title: {
          text: title,
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: legendData,
        },
        xAxis: {
          type: "category",
          data: categories,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData.map((series) => ({
          name: series.name.includes("Artistes")
            ? "Artistes"
            : series.name.includes("Producteurs")
            ? "Producteurs"
            : "Spectateurs",
          type: chartType,
          stack: "total",
          data: series.values,
          itemStyle: {
            color: series.name.includes("Artistes")
              ? colors.Artistes
              : series.name.includes("Producteurs")
              ? colors.Producteurs
              : colors.Spectateurs,
          },
        })),
      };
    case "bar2":
      return {
        title: {
          // text: title,
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          // data: seriesData.map((series) => series.name),
        },
        xAxis: {
          type: "category",
          data: categories,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData.map((series) => ({
          name: series.name,
          type: "bar",
          data: series.values,
          itemStyle: {
            color: series.name.includes("Vues") ? "orange" : "green",
          },
        })),
      };
    case "line":
      return {
        title: {
          text: title,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: seriesData.map((serie) => serie.name),
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        xAxis: {
          type: "category",
          data: categories,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData.map((serie) => ({
          name: serie.name,
          type: "line",
          data: serie.values,
        })),
      };
    case "line2":
      return {
        title: {
          // text: title,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: seriesData.map((serie) => serie.name),
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        xAxis: {
          type: "category",
          data: categories,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData.map((serie) => ({
          name: serie.name,
          type: "line",
          step: "start",
          data: serie.values.filter((value, index) =>
            filteredCategories.includes(categories[index])
          ),
        })),
      };
    case "pie":
      return {
        title: {
          // text: title,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: seriesData.map((serie) => serie.name),
        },

        series: [
          {
            name: title,
            type: "pie",
            radius: "50%",
            center: ["50%", "60%"],
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    case "stacked":
      return {
        title: {
          text: title,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: seriesData.map((serie) => serie.name),
        },
        xAxis: {
          type: "category",
          data: categories,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData.map((serie) => ({
          name: serie.name,
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          data: serie.values,
        })),
      };
    case "runningBar":
      return {
        title: {
          text: title,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Vues", "Likes"],
        },
        xAxis: {
          type: "category",
          data: categories,
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
        },
        yAxis: {
          type: "value",
        },
        series: seriesData.map((serie, index) => ({
          name: serie.name,
          type: "line",
          data: serie.values,
          animationDuration: 7000,
          endLabel: {
            show: true,
            formatter: (params) => `${serie.name}: ${params.value}`,
          },
          itemStyle: {
            color: serie.name === "Vues" ? "blue" : "green",
          },
        })),
      };
    default:
      break;
  }
};
