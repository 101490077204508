import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchAllUsers } from "../../redux/userSlice";
import {
  createConversation,
  fetchConversations,
  toggleCreateConversationModal,
} from "../../redux/messagesSlice";

const CreateConversationModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.list);
  const currentUser = useSelector((state) => state.user.user);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState("");

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedUsers.length < 1) {
      alert("Veuillez sélectionner au moins 1 utilisateur.");
      return;
    }
    dispatch(
      createConversation({
        usersIds: [currentUser._id, ...selectedUsers.map((user) => user.value)],
        groupName,
      })
    );
    setSelectedUsers([]);
    setGroupName("");
    onClose();
  };

  const handleClose = () => {
    // dispatch(toggleCreateConversationModal());
    onClose();
  };

  const handleChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions || []);
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const options = users.map((user) => ({
    value: user._id,
    label: (
      <div className="select-users">
        <img
          src={user.photo_profil}
          alt="profile"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
        <p>{user.username}</p>
      </div>
    ),
    text: user.username,
  }));

  const filterOption = (option, searchText) => {
    if (!searchText) return true;

    const lowercasedSearchText = searchText.toLowerCase();
    const lowercasedText = option.data.text.toLowerCase();

    return lowercasedText.includes(lowercasedSearchText);
  };

  return (
    <div className="create-conversation-modal">
      <div className="modal-content">
        <span className="close-button" onClick={handleClose}>
          &times;
        </span>
        <h2>Nouveau Chat</h2>
        <form onSubmit={handleSubmit}>
          {/* <label htmlFor="users">Users</label> */}
          <Select
            isMulti
            name="users"
            title="users"
            options={options}
            onChange={handleChange}
            value={selectedUsers}
            filterOption={filterOption}
          />

          <label htmlFor="groupName">Nom de la conversation (optionnel)</label>
          <input
            type="text"
            id="groupName"
            value={groupName}
            onChange={handleGroupNameChange}
          />

          <button type="submit">Create</button>
        </form>
      </div>
    </div>
  );
};

export default CreateConversationModal;
