// src/pages/Register.js
import React, { useState } from "react";
import "./Signup.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleAuth, signUp } from "../../redux/authSlice";
import logo from "../../assets/images/logo.svg";
import {
	GoogleOAuthProvider,
	GoogleLogin,
	useGoogleOAuth,
} from "@react-oauth/google";

const Register = () => {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [role, setRole] = useState("artiste");
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authStatus = useSelector((state) => state.auth.status);
	const authError = useSelector((state) => state.auth.error);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError(null);

		try {
			const resultAction = await dispatch(
				signUp({ username, email, password, role })
			);
			if (signUp.fulfilled.match(resultAction)) {
				navigate("/home/");
			} else {
				setError(resultAction.payload?.error || "Failed to register");
			}
		} catch (err) {
			console.error("Failed to register", err);
			setError(err.message);
		}
	};

	const handleGoogleSuccess = async (response) => {
		const token = response.credential;

		try {
			const resultAction = await dispatch(googleAuth({ token }));
			if (googleAuth.fulfilled.match(resultAction)) {
				navigate("/home/");
			} else {
				setError(
					resultAction.payload?.error || "Failed to register with Google"
				);
			}
		} catch (err) {
			console.error("Failed to register with Google", err);
			setError(err.message);
		}
	};

	const handleGoogleFailure = (error) => {
		console.error("Google Sign-In Failure", error);
		setError("Failed to register with Google");
	};

	return (
		<GoogleOAuthProvider clientId="256426583838-7i3k39uumbkddn46d8pdavq9ve9sduok.apps.googleusercontent.com">
			<div className="signup-container">
				<div className="signup-content">
					<div className="signup-header">
						<h3>
							Près de 25K nouveaux utilisateurs <br /> ce mois-ci !
						</h3>
					</div>
					<div className="signup-body">
						<img src={logo} alt="Logo" />
						<form onSubmit={handleSubmit} className="signup-form">
							<h2>Register</h2>
							<input
								type="text"
								value={username}
								placeholder="Nom d'utilisateur"
								onChange={(e) => setUsername(e.target.value)}
							/>
							<input
								type="email"
								value={email}
								placeholder="Adresse e-mail"
								onChange={(e) => setEmail(e.target.value)}
							/>
							<input
								type="password"
								value={password}
								placeholder="Mot de passe"
								onChange={(e) => setPassword(e.target.value)}
							/>
							{error && <p style={{ color: "red" }}>{error}</p>}
							<button type="submit" disabled={authStatus === "loading"}>
								Register
							</button>
							<p>
								Vous avez déjà un compte ? <a href="/login">Connectez-vous</a>
							</p>
						</form>
						{authStatus === "loading" && <p>Loading...</p>}
						{authError && <p style={{ color: "red" }}>{authError}</p>}
						<hr />
						<h3>Ou inscrivez-vous avec</h3>
						<GoogleLogin
							onSuccess={handleGoogleSuccess}
							onFailure={handleGoogleFailure}
							buttonText="S'inscrire avec Google"
							theme="outline"
							size="large"
						/>
						{/* <GoogleButton /> */}
					</div>
				</div>
				<div className="signup-footer">
					<div className="signup-footer-left">
						<p>Terms & Conditions</p>
						<p>Privacy Policy</p>
					</div>
					<div className="signup-footer-right">
						<p>© 2021 Wave</p>
					</div>
				</div>
			</div>
		</GoogleOAuthProvider>
	);
};

export default Register;
