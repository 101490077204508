import React, { useEffect, useState } from "react";
import "./Post.css";
import heart from "../../assets/images/heart.svg";
import profile from "../../assets/images/profile.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import {
	createComment,
	fetchPublication,
	fetchPublicationsByUserId,
	likePublication,
	subscribeToUser,
	unlikePublication,
	unsubscribeFromUser,
} from "../../redux/publicationSlice";
import { renderContent } from "../PostCard/PostCard";
import send from "../../assets/images/send.svg";
import heartFilled from "../../assets/images/heartfullfilled.svg";
import { SubscribeToUser } from "../../redux/userSlice";
import { FaBellSlash, FaBell } from "react-icons/fa";
import { MdOutlinePermMedia } from "react-icons/md";

const Post = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const feed = useSelector((state) => state.publications.feed.items);
	const post = useSelector((state) => state.publications.post.post);
	const postStatus = useSelector((state) => state.publications.post.status);
	const error = useSelector((state) => state.publications.post.error);
	const user = useSelector((state) => state.user.user);
	const [commentText, setCommentText] = useState("");
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [isLiked, setIsLiked] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [showSubscriptionOptions, setShowSubscriptionOptions] = useState(false);
	const [notificationPreference, setNotificationPreference] = useState("all");

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	useEffect(() => {
		if (id) {
			dispatch(fetchPublication(id));
		}
	}, [id, dispatch]);

	useEffect(() => {
		if (post && post.likedBy) {
			setIsLiked(post.likedBy.includes(user._id));
		}
	}, [post, user]);

	useEffect(() => {
		if (post?.poster?._id) {
			dispatch(fetchPublicationsByUserId(post.poster._id));
		}
	}, [post, dispatch]);

	useEffect(() => {
		if (user && post?.poster?._id) {
			const isUserSubscribed = post.poster.abonnements?.some(
				(sub) => sub.user === user._id
			);
			setIsSubscribed(isUserSubscribed);
		}
	}, [post, user]);

	const handleCommentChange = (e) => setCommentText(e.target.value);
	const handleCommentSubmit = (e) => {
		e.preventDefault();
		if (commentText.trim()) {
			dispatch(
				createComment({
					publicationId: id,
					commentData: { content: commentText, parent_id: id },
				})
			);
			setCommentText("");
		}
	};

	const handleLikeToggle = (event) => {
		event.preventDefault();
		if (isLiked) {
			dispatch(unlikePublication(id)).then(() => setIsLiked(false));
		} else {
			dispatch(likePublication(id)).then(() => setIsLiked(true));
		}
	};

	const handleSubscribeToggle = (event) => {
		event.preventDefault();
		if (isSubscribed) {
			dispatch(unsubscribeFromUser(post.poster._id)).then(() => setIsSubscribed(false));
		} else {
			setShowSubscriptionOptions(!showSubscriptionOptions);
		}
	};

	const handleSubscriptionSelection = (preference) => {
		setNotificationPreference(preference);
		dispatch(
			SubscribeToUser({
				targetUserId: post.poster._id,
				notificationPreference: preference,
			})
		).then(() => {
			setIsSubscribed(true);
			setShowSubscriptionOptions(false);
		});
	};




	const renderPostContent = (
		post,
		handlePlay,
		handlePause,
		isHovered,
		className
	) => {
		switch (post.type) {
			case "text":
				return <p>{post.contenu}</p>;
			case "image":
				return <img className="post-media" src={post.contenu} alt={post.titre} />;
			case "video":
				return (
					<video
						preload="metadata"
						className={`VideoPostContainer`}
						// autoPlay
						// affiche les controles si la souris est sur la vidéo
						controls={isHovered}
						onPlay={handlePlay}
						onPause={handlePause}
					>
						<source src={post.contenu} type="video/mp4" />
						Votre navigateur ne supporte pas la vidéo.
					</video>
				);
			case "audio":
				return (
					<div className="Container">
						<audio controls className="AudioContainer">
							<source src={post.contenu} type="audio/mpeg" />
							Votre navigateur ne supporte pas l'élément audio.
						</audio>
					</div>
				);
			default:
				console.error("Type de contenu non pris en charge", post);
				return null;
		}
	};


	if (postStatus === "loading") return <p>Loading...</p>;
	if (postStatus === "failed") return <p>{error?.message || "An unknown error occurred"}</p>;

	if (!post || !user) return <div>Loading...</div>;

	return (
		<div className="post-unique-card">
			<button className="back-button" onClick={() => window.history.back()}>
				<HiOutlineArrowSmLeft />
			</button>
			<div className="post-unique-header">
				<div
					className="post-unique-content"
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					{renderPostContent(post)}
				</div>
				<div className="bloc1">
					<div className="post-unique-header">
						<div className="post-unique-author-info">
							<h3 className="post-unique-title">{post.titre}</h3>
							<div className="post-unique-tag">
								<span>{post.tag}</span>
							</div>
							<span className="post-unique-date">
								{new Date(post.date_creation).toLocaleDateString()}
							</span>
						</div>
					</div>
					<div className="post-unique-user-container">
						<div className="user-likes">
							<img
								src={post.poster.photo_profil || profile}
								alt="Author"
								className="post-unique-author-avatar"
								onClick={() => navigate(`/home/profileuser/${post.poster._id}`)}
							/>
							<div
								className="username-abo"
								onClick={() => navigate(`/home/profileuser/${post.poster._id}`)}
							>
								<h1>{post.poster.username}</h1>
							</div>
							<button className="follow-button" onClick={handleSubscribeToggle}>
								{isSubscribed ? "Se désabonner" : "S'abonner"}
								{showSubscriptionOptions && (
									<div className="dropdown-menu-subscribe">
										<div
											className="dropdown-item"
											onClick={() => handleSubscriptionSelection("all")}
										>
											<FaBell />
											Toutes les notifications
										</div>
										<div
											className="dropdown-item"
											onClick={() => handleSubscriptionSelection("publications")}
										>
											<MdOutlinePermMedia />
											Uniquement les publications
										</div>
										<div
											className="dropdown-item"
											onClick={() => handleSubscriptionSelection("none")}
										>
											<FaBellSlash />
											Aucune Notifications
										</div>
									</div>
								)}
							</button>
						</div>
						<div className="post-unique-like-count">
							<img
								src={isLiked ? heartFilled : heart}
								alt="Likes"
								onClick={handleLikeToggle}
								className="like-icon"
								style={{ cursor: "pointer" }}
							/>
							<p>
								{post.likes.total.total} <span>likes</span>
							</p>
							<p>
								{post.views.total.total} <span> vues</span>
							</p>
						</div>
					</div>
				</div>
				<div className="comment-section">
					<div className="comments-number">
						<p>{post.commentaires.length} commentaires</p>
					</div>
					<div className="post-comment">
						<input
							type="text"
							placeholder="Ajouter un commentaire"
							value={commentText}
							onChange={handleCommentChange}
						/>
						<div className="send">
							<img src={send} alt="Send" onClick={handleCommentSubmit} />
						</div>
					</div>
					<div className="comments-list">
						{post.commentaires.map((comment) => (
							<div key={comment._id} className="comment">
								<img
									src={comment.author.photo_profil || profile}
									alt="Comment Author"
									className="comment-author-avatar"
								/>
								<div className="comment-content">
									<div className="author-date">
										<h3>{comment.author.username}</h3>
										<p>
											Le {new Date(comment.createdAt).toLocaleDateString()}
										</p>
									</div>
									<p>{comment.content}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="video-scroll">
				{feed.map((postItem) => (
					<div className="video-scroll-card" key={postItem._id} onClick={() => navigate(`/home/post/${postItem._id}`)}>
						<div className="video-scroll-card-content">
							<div className="video-scroll-card-info">
								<div className="content-scroll">
									{postItem.contenu && renderContent(postItem)}
								</div>
								<div className="scroll-author-info">
									<h3 className="video-scroll-card-title">{postItem.titre}</h3>
									<div className="scroll-author-date">
										<p className="video-author">{postItem.username}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Post;
