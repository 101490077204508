import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { googleLogin, login } from "../../redux/authSlice";
import "./Login.css";
import logo from "../../assets/images/logo.svg";
import googleLogo from "../../assets/images/logo-google.svg";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(null);
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const authStatus = useSelector((state) => state.auth.status);
	const authError = useSelector((state) => state.auth.error);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError(null);

		try {
			const resultAction = await dispatch(login({ email, password }));
			if (login.fulfilled.match(resultAction)) {
				navigate("/home"); // Ajuster la route si nécessaire
			} else {
				setError(resultAction.payload?.error || "Échec de la connexion");
			}
		} catch (err) {
			setError(err.message);
		}
	};

	const handleGoogleSuccess = async (response) => {
		const token = response.credential;

		try {
			const resultAction = await dispatch(googleLogin({ token }));
			if (googleLogin.fulfilled.match(resultAction)) {
				navigate("/home/");
			} else {
				setError(resultAction.payload?.error || "Failed to login with Google");
			}
		} catch (err) {
			console.error("Failed to login with Google", err);
			setError(err.message);
		}
	};

	const handleGoogleFailure = (response) => {
	};

	return (
		<GoogleOAuthProvider clientId="256426583838-7i3k39uumbkddn46d8pdavq9ve9sduok.apps.googleusercontent.com">
			<div className="login-container">
				<div className="login-header">
					<h3>
						Près de 25K nouveaux utilisateurs <br /> ce mois-ci !
					</h3>
				</div>
				<div className="login-body">
					<img src={logo} alt="Logo" className="login-logo" />
					<form onSubmit={handleSubmit} className="login-form">
						<h2>Bienvenue !</h2>
						<input
							type="email"
							placeholder="Adresse e-mail"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<input
							type="password"
							value={password}
							placeholder="Mot de passe"
							onChange={(e) => setPassword(e.target.value)}
						/>
						{error && <p style={{ color: "red" }}>{error}</p>}
						<div className="remember">
							<input type="checkbox" id="remember" name="remember" />
							<label>Se souvenir de moi</label>
						</div>
						<button type="submit" disabled={authStatus === "loading"}>
							Connexion
						</button>
						<p className="signup-link">
							Vous n'avez pas de compte ? <a href="/signup">Inscrivez-vous</a>
						</p>
						<div className="divider">
							<span></span>
						</div>
						<GoogleLogin
							onSuccess={handleGoogleSuccess}
							onFailure={handleGoogleFailure}
							buttonText="S'inscrire avec Google"
							theme="outline"
							size="large"
						/>
					</form>
					{authStatus === "loading" && <p>Loading...</p>}
					{authError && <p style={{ color: "red" }}>{authError}</p>}
				</div>
				<div className="login-footer">
					<div className="login-footer-left">
						<p>Terms & Conditions</p>
						<p>Privacy Policy</p>
					</div>
					<div className="login-footer-right">
						<p>© 2021 Wave</p>
					</div>
				</div>
			</div>
		</GoogleOAuthProvider>
	);
};

export default Login;
