import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserStats, profil } from "../../redux/userSlice";
import TotalViewsLikesChart from "./TotalViewsLikesChart";
import DetailedViewsLikesChart from "./DetailedViewsLikesChart";
import PieChart from "./PieChart";
import WeeklyStatsChart from "./WeeklyStatsChart";
import "./Dashboard.css";
import noData from "../../assets/images/noData.png";

const Dashboard = () => {
	const dispatch = useDispatch();
	const { stats, status: statsStatus, error: statsError } = useSelector((state) => state.user.stats);
	const { user, status, error } = useSelector((state) => state.user.user);

	const [dataTypeBar, setDataTypeBar] = useState("views");
	const [dataTypePie, setDataTypePie] = useState("views");
	const [filteredPeriod, setFilteredPeriod] = useState("all");
	const [selectedPublication, setSelectedPublication] = useState(null);
	const [modalOpenTime, setModalOpenTime] = useState(false);
	const [modalOpenBar, setModalOpenBar] = useState(false);
	const [modalOpenPie, setModalOpenPie] = useState(false);

	// Toggle functions for modals
	const toggleModalTime = () => setModalOpenTime(!modalOpenTime);
	const toggleModalBar = () => setModalOpenBar(!modalOpenBar);
	const toggleModalPie = () => setModalOpenPie(!modalOpenPie);

	// Handler functions for changing filters and types
	const handleFilterPeriodChange = (period) => setFilteredPeriod(period);
	const handleDataTypeChangeBar = (type) => setDataTypeBar(type);
	const handleDataTypeChangePie = (type) => setDataTypePie(type);

	const handlePublicationChange = (e) => {
		const selectedTitle = e.target.value;
		const publication = stats.find((stat) => stat.titre === selectedTitle);
		setSelectedPublication(publication);
	};

	useEffect(() => {
		console.log("statsStatus", statsStatus);
		if (statsStatus === "idle") {
			dispatch(fetchUserStats());
			setSelectedPublication(stats[0]);
		}
	}, [dispatch, stats]);

	if (statsStatus === "loading") {
		return <h4>Loading...</h4>;
	}

	if (statsStatus === "failed") {
		return <div>Error: {error}</div>;
	}

	const formatDate = (date) => {
		return date ? new Date(date).toISOString().split("T")[0] : "N/A";
	};

	const categories = [];
	let totalViewsCount = 0;
	let totalLikesCount = 0;

	stats.forEach((stat) => {
		const filteredLikesHistory = stat.likesHistory || [];
		const filteredViewsHistory = stat.viewsHistory || [];

		filteredLikesHistory.forEach((like) => {
			const date = formatDate(like.date);
			if (!categories.includes(date)) {
				categories.push(date);
			}
			totalLikesCount += like.total;
		});

		filteredViewsHistory.forEach((view) => {
			const date = formatDate(view.date);
			if (!categories.includes(date)) {
				categories.push(date);
			}
			totalViewsCount += view.total;
		});
	});

	categories.sort((a, b) => new Date(a) - new Date(b));

	const cumulativeSeriesData = stats.map((stat) => ({
		name: stat.titre,
		values: categories.map((date) => {
			const viewForDate = stat.viewsHistory.find((view) => formatDate(view.date) === date);
			return viewForDate ? viewForDate.total : 0;
		}),
	}));

	const seriesDataViewsDetailed = {
		artistes: stats.map((stat) => ({
			name: `${stat.titre} - Artistes`,
			values: categories.map((date) => {
				const viewForDate = stat.viewsHistory.find((view) => formatDate(view.date) === date);
				return viewForDate ? viewForDate.artistes : 0;
			}),
		})),
		producteurs: stats.map((stat) => ({
			name: `${stat.titre} - Producteurs`,
			values: categories.map((date) => {
				const viewForDate = stat.viewsHistory.find((view) => formatDate(view.date) === date);
				return viewForDate ? viewForDate.producteurs : 0;
			}),
		})),
		spectateurs: stats.map((stat) => ({
			name: `${stat.titre} - Spectateurs`,
			values: categories.map((date) => {
				const viewForDate = stat.viewsHistory.find((view) => formatDate(view.date) === date);
				return viewForDate ? viewForDate.spectateurs : 0;
			}),
		})),
	};

	const seriesDataLikesDetailed = {
		artistes: stats.map((stat) => ({
			name: `${stat.titre} - Artistes`,
			values: categories.map((date) => {
				const likeForDate = stat.likesHistory.find((like) => formatDate(like.date) === date);
				return likeForDate ? likeForDate.artistes : 0;
			}),
		})),
		producteurs: stats.map((stat) => ({
			name: `${stat.titre} - Producteurs`,
			values: categories.map((date) => {
				const likeForDate = stat.likesHistory.find((like) => formatDate(like.date) === date);
				return likeForDate ? likeForDate.producteurs : 0;
			}),
		})),
		spectateurs: stats.map((stat) => ({
			name: `${stat.titre} - Spectateurs`,
			values: categories.map((date) => {
				const likeForDate = stat.likesHistory.find((like) => formatDate(like.date) === date);
				return likeForDate ? likeForDate.spectateurs : 0;
			}),
		})),
	};

	const pieChartData =
		selectedPublication && dataTypePie === "views"
			? [
				{ name: "Artistes", value: selectedPublication.viewsTotal.artistes },
				{ name: "Producteurs", value: selectedPublication.viewsTotal.producteurs },
				{ name: "Spectateurs", value: selectedPublication.viewsTotal.spectateurs },
			]
			: selectedPublication && dataTypePie === "likes"
				? [
					{ name: "Artistes", value: selectedPublication.likesTotal.artistes },
					{ name: "Producteurs", value: selectedPublication.likesTotal.producteurs },
					{ name: "Spectateurs", value: selectedPublication.likesTotal.spectateurs },
				]
				: [];

	const daysOfWeek = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
	const daySeriesData = [
		{
			name: "Vues",
			values: stats.reduce(
				(acc, stat) =>
					stat.viewsHistory.map((view, idx) => acc[idx] + view.total),
				new Array(7).fill(0)
			),
		},
		{
			name: "Likes",
			values: stats.reduce(
				(acc, stat) =>
					stat.likesHistory.map((like, idx) => acc[idx] + like.total),
				new Array(7).fill(0)
			),
		},
	];

	return (
		<div className="dash-container">
			<h1>Dashboard</h1>
			{user?.posts.length > 0 ? (
				<div className="dashboard-grid">
					<TotalViewsLikesChart
						categories={categories}
						cumulativeSeriesData={cumulativeSeriesData}
						modalOpenTime={modalOpenTime}
						toggleModalTime={toggleModalTime}
						filteredPeriod={filteredPeriod}
						handleFilterPeriodChange={handleFilterPeriodChange}
					/>
					<DetailedViewsLikesChart
						categories={categories}
						dataTypeBar={dataTypeBar}
						toggleModalBar={toggleModalBar}
						modalOpenBar={modalOpenBar}
						handleDataTypeChangeBar={handleDataTypeChangeBar}
						seriesDataViewsDetailed={seriesDataViewsDetailed}
						seriesDataLikesDetailed={seriesDataLikesDetailed}
					/>
					<PieChart
						selectedPublication={selectedPublication}
						dataTypePie={dataTypePie}
						pieChartData={pieChartData}
						modalOpenPie={modalOpenPie}
						toggleModalPie={toggleModalPie}
						handleDataTypeChangePie={handleDataTypeChangePie}
						handlePublicationChange={handlePublicationChange}
						stats={stats}
					/>
					<WeeklyStatsChart
						daysOfWeek={daysOfWeek}
						daySeriesData={daySeriesData}
					/>
				</div>
			) : (
				<div className="no-video">
					<h2>Vous n'avez pas encore de publications</h2>
					<h3>Publiez votre première vidéo pour voir vos statistiques</h3>
					<img src={noData} alt="no data" className="noData" />
				</div>
			)}
		</div>
	);
};

export default Dashboard;
