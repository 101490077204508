import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MyAxios from "../Services/Interceptor";

export const fetchNotifications = createAsyncThunk(
	"notifications/fetchNotifications",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("notifications/");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const addNotification2 = createAsyncThunk(
	"notifications/addNotification",
	async (notification, { rejectWithValue }) => {
		try {
			return notification;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);


export const deleteNotification = createAsyncThunk(
	"notifications/deleteNotification",
	async (id, { rejectWithValue }) => {
		try {
			await MyAxios.delete(`/notifications/${id}`);
			return id; // Retourne l'id de la notification supprimée
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const deleteAllNotifications = createAsyncThunk(
	"notifications/deleteAllNotifications",
	async (userId, { rejectWithValue }) => {
		try {
			await MyAxios.delete(`/notifications/all/${userId}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const notificationSlice = createSlice({
	name: "notifications",
	initialState: {
		list: [],
		isModalOpen: false,
		status: "idle",
		error: null,
	},
	reducers: {
		addNotification: (state, action) => {
			state.list.push(action.payload);
		},
		toggleNotificationModal: (state) => {
			state.isModalOpen = !state.isModalOpen;
		},
		markAsRead: (state, action) => {
			state.list = state.list.map((notification) =>
				notification.id === action.payload
					? { ...notification, read: true }
					: notification
			);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addNotification2.fulfilled, (state, action) => {
				state.list.push(action.payload);
			})
			.addCase(fetchNotifications.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchNotifications.fulfilled, (state, action) => {
				state.list = action.payload;
				state.status = "succeeded";
			})
			.addCase(fetchNotifications.rejected, (state, action) => {
				state.status = "failed";
			})
			.addCase(deleteNotification.fulfilled, (state, action) => {
				state.notifications = state.list.filter(
					(notification) => notification._id !== action.payload
				);
			})
			.addCase(deleteAllNotifications.fulfilled, (state, action) => {
				state.notifications = [];
			});
	},
});

export const { addNotification, toggleNotificationModal, markAsRead } =
	notificationSlice.actions;

export default notificationSlice.reducer;
