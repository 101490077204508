import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MyAxios from "../Services/Interceptor";

export const createProject = createAsyncThunk(
	"projects/createProject",
	async (projectData, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/project/creation", projectData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchProjects = createAsyncThunk(
	"projects/fetchProjects",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/project/");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchCollaborations = createAsyncThunk(
	"projects/fetchCollaborations",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/project/collaborations");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchProjectById = createAsyncThunk(
	"projects/fetchProject",
	async (projectId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`/project/${projectId}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const joinProject = createAsyncThunk(
	"projects/joinProject",
	async (projectId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(`/project/${projectId}/register`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const acceptCandidate = createAsyncThunk(
	"projects/acceptCandidate",
	async ({ projectId, userId }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/project/${projectId}/${userId}/member`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const deleteMemberFromProject = createAsyncThunk(
	"projects/deleteMemberFromProject",
	async ({ projectId, userId }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.delete(
				`/project/${projectId}/${userId}/member`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const addCommentToProject = createAsyncThunk(
	"projects/addCommentToProject",
	async ({ projectId, commentData }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/project/${projectId}/comment`,
				commentData
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchProjectComments = createAsyncThunk(
	"projects/fetchProjectComments",
	async (projectId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`/project/${projectId}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const updateProject = createAsyncThunk(
	"projects/updateProject",
	async ({ projectId, projectData }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.patch(
				`/project/${projectId}/update`,
				projectData
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const responseToCommentaire = createAsyncThunk(
	"projects/responseToComment",
	async ({ projectId, commentId, responseData }, { rejectWithValue }) => {
		try {
			const res = await MyAxios.post(
				`/project/${projectId}/comment/${commentId}/response`,
				responseData
			);
			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const declineCandidate = createAsyncThunk(
	"projects/declineCandidate",
	async ({ projectId, userId }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.delete(
				`/project/${projectId}/${userId}/decline`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const projectSlice = createSlice({
	name: "projects",
	initialState: {
		projects: [],
		collaborations: [],
		collaborationError: null,
		collaboration: {},
		collaborationStatus: "idle",
		project: {},
		status: "idle",
		error: null,
		commentaires: [],
	},
	reducers: {
		clearProject(state) {
			state.project = null;
			state.status = "idle";
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCollaborations.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchCollaborations.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaborations.push(action.payload);
			})
			.addCase(fetchCollaborations.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(createProject.pending, (state) => {
				state.status = "loading";
			})
			.addCase(createProject.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.projects.push(action.payload);
			})
			.addCase(createProject.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchProjects.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchProjects.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.projects = action.payload;
			})
			.addCase(fetchProjects.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(joinProject.pending, (state) => {
				state.status = "loading";
			})
			.addCase(joinProject.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.projects.push(action.payload);
			})
			.addCase(joinProject.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchProjectById.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchProjectById.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.project = action.payload;
				state.commentaires = action.payload.commentaires || [];
			})
			.addCase(fetchProjectById.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(addCommentToProject.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addCommentToProject.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.project.commentaires.push(action.payload);
			})
			.addCase(addCommentToProject.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchProjectComments.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchProjectComments.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.commentaires = action.payload;
			})
			.addCase(fetchProjectComments.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(responseToCommentaire.pending, (state) => {
				state.status = "loading";
			})
			.addCase(responseToCommentaire.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.project.commentaires.push(action.payload);
			})
			.addCase(responseToCommentaire.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(acceptCandidate.pending, (state) => {
				state.status = "loading";
			})
			.addCase(acceptCandidate.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.project.inscrits.push(action.payload);
			})
			.addCase(acceptCandidate.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(deleteMemberFromProject.pending, (state) => {
				state.status = "loading";
			})
			.addCase(deleteMemberFromProject.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.project.inscrits = state.project.inscrits.filter(
					(member) => member._id !== action.payload
				);
			})
			.addCase(deleteMemberFromProject.rejected, (state, action) => {
				state.status = "failed";
				state.error = action;
			})

			.addCase(declineCandidate.pending, (state) => {
				state.status = "loading";
			})
			.addCase(declineCandidate.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.project.inscrits = state.project.inscrits.filter(
					(member) => member._id !== action.payload
				);
			})
			.addCase(declineCandidate.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(updateProject.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateProject.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.project = action.payload;
			})
			.addCase(updateProject.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			});
	},
});

export const { clearProject } = projectSlice.actions;

export default projectSlice.reducer;
