import React, { useState } from "react";
import "./PostCard.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { likePost } from "../../redux/publicationSlice";
import heart from "../../assets/images/heart.svg";
import { CiHeart } from "react-icons/ci";

export const renderContent = (
	post,
	handlePlay,
	handlePause,
	isHovered,
	className
) => {
	switch (post.type) {
		case "text":
			return <p>{post.contenu}</p>;
		case "image":
			return <img className="post-media" src={post.contenu} alt={post.titre} />;
		case "video":
			return (
				<video
					preload="metadata"
					muted
					controls={isHovered}
					className={`VideoContainer ${className}`}
					onMouseEnter={(e) => e.target.play()}
					onMouseLeave={(e) => e.target.pause()}
					onPlay={handlePlay}
					onPause={handlePause}
				>
					<source src={post.contenu} type="video/mp4" />
					Votre navigateur ne supporte pas la vidéo.
				</video>
			);
		case "audio":
			return (
				<div className="Container">
					<audio controls className="AudioContainer">
						<source src={post.contenu} type="audio/mpeg" />
						Votre navigateur ne supporte pas l'élément audio.
					</audio>
				</div>
			);
		default:
			console.error("Type de contenu non pris en charge", post);
			return null;
	}
};

const PostCard = ({ post, large }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLiked, setIsLiked] = useState(
		post?.likedBy?.includes("current-user-id") || false
	);
	const user = useSelector((state) => state.user.user.user);
	const [isHovered, setIsHovered] = useState(false);

	const [isPlaying, setIsPlaying] = useState(false);

	const handleLike = (e) => {
		e.preventDefault();
		dispatch(likePost(post._id));
		setIsLiked(!isLiked);
	};

	const handlePlay = () => {
		setIsPlaying(true);
	};

	const handlePause = () => {
		setIsPlaying(false);
	};

	if (!post) {
		return <>
			<div>Chargement des publications...</div>
		</>;
	}

	const postUser = post.utilisateur_id || {};

	return (
		<Link
			className={`post-card ${large ? "post-card-large" : "post-card-small"}`}
			to={`/home/post/${post._id}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className="post-header">
				<div className="post-user-container">
					<div className="username-profil">
						<div className="picture-author">
							<img
								src={
									postUser.photo_profil ||
									user.photo_profil ||
									"default-profile.png"
								}
								alt="Photo de profil"
								className="roud-avatar"
							/>

							<div className="post-author-info">
								<h3 className="post-author-name">
									{postUser.username || post.username}
								</h3>
								<div className="post-date">
									<p> Le {new Date(post.date_creation).toLocaleDateString()}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="post-content">
				{renderContent(post, handlePlay, handlePause, isHovered)}
				<h3 className={`post-title ${isPlaying ? "hidden" : ""}`}>
					{post.titre}
				</h3>
			</div>
			<div className="post-footer">
				<p>
					{post?.commentaires?.length} <span>commentaires</span>
				</p>
				<button className="post-like-button" onClick={handleLike}>
					<CiHeart className={isLiked ? "CiHeart liked" : "CiHeart"} />
					<p>
						{post?.likes?.total.total} <span>likes -{" "}</span>
						{post?.views?.total.total} <span>vues</span>
					</p>
				</button>
			</div>
		</Link>
	);
};

export default PostCard;
