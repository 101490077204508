import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createProject } from "../../redux/projectSlice";
import "./CreateProject.css";

const CreateProject = ({ handleCloseUploadModal }) => {
  const dispatch = useDispatch();
  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [categorie, setCategorie] = useState("");
  const [visibility, setVisibility] = useState("");
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const projectData = {
      titre,
      description,
      categorie,
      visibility,
      status,
      progress,
      date_debut: dateDebut,
      date_fin: dateFin,
    };

    dispatch(createProject(projectData));
    // Reset form fields
    setTitre("");
    setDescription("");
    setCategorie("");
    setVisibility("");
    setStatus("");
    setProgress("");
    setDateDebut("");
    setDateFin("");
  };

  return (
    <div className="create-project-container">
      <h2>Créer un Projet</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="titre">Titre</label>
          <input
            type="text"
            id="titre"
            value={titre}
            onChange={(e) => setTitre(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="categorie">Catégorie</label>
          <select
            id="categorie"
            value={categorie}
            onChange={(e) => setCategorie(e.target.value)}
            required
          >
            <option value="">Sélectionnez une catégorie</option>
            <option value="musique">Musique</option>
            <option value="film">Film</option>
            <option value="livre">Livre</option>
            <option value="jeu video">Jeu Vidéo</option>
            <option value="application">Application</option>
            <option value="script">Script</option>
            <option value="autre">Autre</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="visibility">Visibilité</label>
          <select
            id="visibility"
            value={visibility}
            onChange={(e) => setVisibility(e.target.value)}
            required
          >
            <option value="">Sélectionnez la visibilité</option>
            <option value="public">Public</option>
            <option value="prive">Privé</option>
            <option value="ami">Ami</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="status">Statut</label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="">Sélectionnez le statut</option>
            <option value="brouillon">Brouillon</option>
            <option value="publie">Publié</option>
            <option value="archive">Archivé</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="progress">Progression</label>
          <select
            id="progress"
            value={progress}
            onChange={(e) => setProgress(e.target.value)}
            required
          >
            <option value="">Sélectionnez la progression</option>
            <option value="Debut">Début</option>
            <option value="Avance">Avancé</option>
            <option value="Fini">Fini</option>
            <option value="En attente">En attente</option>
            <option value="Annule">Annulé</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="dateDebut">Date de début</label>
          <input
            type="date"
            id="dateDebut"
            value={dateDebut}
            onChange={(e) => setDateDebut(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="dateFin">Date de fin</label>
          <input
            type="date"
            id="dateFin"
            value={dateFin}
            onChange={(e) => setDateFin(e.target.value)}
          />
        </div>
        <button type="submit">Créer</button>
      </form>
      <div className="close-button-modal">
        <button onClick={handleCloseUploadModal}>Fermer</button>
      </div>
    </div>
  );
};

export default CreateProject;
