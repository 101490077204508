import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects, fetchCollaborations, joinProject } from "../../redux/projectSlice";
import CreateProject from "../../Components/CreateProject/CreateProject";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import CollaborationCard from "../../Components/CollaborationCard/CollaborationCard";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import FilterSidebar from "../../Components/FilterSidebar/FilterSidebar";
import "./Projects.css";

const ProjectList = () => {
	const dispatch = useDispatch();
	const { projects, status: projectStatus, error: projectError } = useSelector((state) => state.projects);
	const { collaborations, status: collaborationStatus, error: collaborationError } = useSelector((state) => state.collaborations);
	const filteredCollaborations = collaborations.filter((collaboration) => {
		return collaboration.titre.toLowerCase().includes(searchTerm);
	});
	const [activeTab, setActiveTab] = useState("projects");
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [filters, setFilters] = useState({
		fileFormat: [],
		category: [],
		users: '',
		tags: ''
	});

	useEffect(() => {
		if (projectStatus === "idle") {
			dispatch(fetchProjects());
		}
		if (collaborationStatus === "idle") {
			dispatch(fetchCollaborations());
		}
	}, [dispatch, projectStatus, collaborationStatus]);

	const handleJoinProject = (projectId, projectName) => {
		dispatch(joinProject(projectId));
		alert(`Vous avez rejoint le projet "${projectName}"`);
	};

	const handleSearch = (e) => {
		setSearchTerm(e.target.value.toLowerCase());
	};

	const handleFilterChange = (e) => {
		const { value, checked } = e.target;
		const filterType = e.target.parentElement.parentElement.querySelector('h4').innerText.toLowerCase().replace(' ', '');

		setFilters((prevFilters) => ({
			...prevFilters,
			[filterType]: checked
				? [...prevFilters[filterType], value]
				: prevFilters[filterType].filter((filterValue) => filterValue !== value)
		}));
	};

	const filteredProjects = projects.filter((project) => {
		return (
			project.titre.toLowerCase().includes(searchTerm) &&
			(filters.category.length === 0 || filters.category.includes(project.categorie)) &&
			(filters.fileFormat.length === 0 || filters.fileFormat.includes(project.fileFormat))
		);
	});

	// Fonction pour ouvrir/fermer la modale
	const toggleUploadModal = () => {
		setIsUploadModalOpen((prevState) => !prevState);
	};

	// Fonction pour afficher la liste des projets
	const renderProjectList = () => {
		if (projectStatus === "loading") {
			return <LoadingSpinner />;
		}

		if (projectStatus === "failed") {
			return <p className="error-message">{projectError}</p>;
		}

		if (filteredProjects.length === 0) {
			return <p>Aucun projet trouvé</p>;
		}

		return (
			<div className="project-list">
				{filteredProjects.map((project) => (
					<ProjectCard
						key={project._id}
						project={project}
						onJoinProject={handleJoinProject}
					/>
				))}
			</div>
		);
	};

	// Fonction pour afficher la liste des collaborations
	const renderCollaborationList = () => {
		if (collaborationStatus === "loading") {
			return <LoadingSpinner />;
		}

		if (collaborationStatus === "failed") {
			return <p className="error-message">{collaborationError}</p>;
		}

		if (filteredCollaborations.length === 0) {
			return <p>Aucune collaboration trouvée</p>;
		}

		return (
			<div className="collaboration-list">
				{filteredCollaborations.map((collaboration) => (
					<CollaborationCard
						key={collaboration._id}
						collaboration={collaboration}
					/>
				))}
			</div>
		);
	};

	return (
		<div className="project-list-container">
			<div className="sidebar">
				<FilterSidebar onFilterChange={handleFilterChange} />
			</div>
			<div className="content-prj">
				<div className="tabs">
					<button className={`tab ${activeTab === "projects" ? "active" : ""}`} onClick={() => setActiveTab("projects")}>
						Projets
					</button>
					<button className={`tab ${activeTab === "collaborations" ? "active" : ""}`} onClick={() => setActiveTab("collaborations")}>
						Collaborations
					</button>
				</div>

				<div className="header">
					<div className="search-add-project">
						<input
							type="text"
							placeholder={activeTab === "projects" ? "Rechercher un projet" : "Rechercher une collaboration"}
							value={searchTerm}
							onChange={handleSearch}
						/>
						{activeTab === "projects" && (
							<button onClick={toggleUploadModal}>Proposer un projet</button>
						)}
					</div>
				</div>

				<div className="tab-content">
					{activeTab === "projects" ? renderProjectList() : renderCollaborationList()}
				</div>

				{isUploadModalOpen && (
					<div className="modal-overlay">
						<div className="upload-modal">
							<CreateProject handleCloseUploadModal={toggleUploadModal} />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProjectList;
