import React from "react";
import "./Subscribe.css";
import redcross from "../../assets/images/redcross.svg";
import check from "../../assets/images/check.svg";

const Subscribe = () => {
  return (
    <div className="subscribe-container">
      <div className="subscribe-content">
        <div className="subscribe-title">
          <p>
            {" "}
            Vous voulez profitez entièrement de Wave? <br />
            Abonnez-vous !{" "}
          </p>
        </div>
        <div className="subscriptions">
          <div className="subscription">
            <div className="plan">
              <h2>Basique</h2>
              <p>------ Plan initial ------</p>
            </div>
            <div className="price">
              <h3>0</h3>
              <p>€</p>
              <span>/mois</span>
            </div>
            <div className="advantages">
              <ul>
                <li>
                  <img src={check} alt="none" />5 Publications
                </li>
                <li>
                  <img src={check} alt="none" />1 Projet
                </li>
                <li>
                  <img src={check} alt="none" /> Annonces publicitaires
                </li>
              </ul>
            </div>
            <div className="subscribe-button rayed-button">
              <button>S'abonner</button>
            </div>
          </div>
          <div className="subscription">
            <div className="plan">
              <h2>Access</h2>
              <p>------ Futur créateur ------</p>
            </div>
            <div className="price">
              <h3>9</h3>
              <p>€</p>
              <span>/mois</span>
            </div>
            <div className="advantages">
              <ul>
                <li>
                  <img src={check} alt="none" />
                  10 Publications
                </li>
                <li>
                  <img src={check} alt="none" />5 Projets
                </li>
                <li>
                  <img src={redcross} alt="none" /> Annonces publicitaires
                </li>
              </ul>
            </div>
            <div className="subscribe-button">
              <button>S'abonner</button>
            </div>
          </div>
          <div className="subscription">
            <div className="plan">
              <h2>Premium</h2>
              <p>------ Créateur confirmé ------</p>
            </div>
            <div className="price">
              <h3>14</h3>
              <p>€</p>
              <span>/mois</span>
            </div>
            <div className="advantages">
              <ul>
                <li>
                  <img src={check} alt="none" />
                  Publications illimitées
                </li>
                <li>
                  <img src={check} alt="none" />
                  Projets illimités
                </li>
                <li>
                  <img src={redcross} alt="none" /> Annonces publicitaires
                </li>
              </ul>
            </div>
            <div className="subscribe-button">
              <button>S'abonner</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
