import React, { useEffect, useRef, useState } from "react";
import "./Messaging.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/images/newchat.svg";
import photodefault from "../../assets/images/photodefault.svg";
import send from "../../assets/images/sendmessage.svg";
import searchIcon from "../../assets/images/searchicon.svg";
import mediaIcon from "../../assets/images/mediaicon.svg";
import moreOptionsIcon from "../../assets/images/moreoptionsicon.svg";
import {
	deleteConversation,
	deleteMessage,
	fetchConversations,
	fetchMessages,
	sendMessage,
	setCurrentConversation,
	updateMessage,
} from "../../redux/messagesSlice";
import { profil } from "../../redux/userSlice";
import { fetchNotifications } from "../../redux/notificationSlice";
import CreateConversationModal from "../../Components/CreateConversationModal/CreateConversationModal";
import MessageModal from "../../Components/MessageModal/MessageModal";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
	MdClose,
	MdCheck,
} from "react-icons/md";
import { IoArrowUndoOutline } from "react-icons/io5";

const Messaging = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [message, setMessage] = useState("");
	const [activeConversationId, setActiveConversationId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
	const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
	const [messageModalPosition, setMessageModalPosition] = useState({ top: 0, left: 0 });
	const [isDeleteConversationModalOpen, setIsDeleteConversationModalOpen] = useState(false);
	const [selectedMessageId, setSelectedMessageId] = useState(null);
	const [selectedMessageContent, setSelectedMessageContent] = useState("");
	const [editingMessageId, setEditingMessageId] = useState(null);
	const [editingMessageContent, setEditingMessageContent] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
	const endOfMessagesRef = useRef(null);
	const [deleteConversationModalPosition, setDeleteConversationModalPosition] = useState({ top: 0, left: 0 });

	const {
		conversations,
		currentConversation,
		conversationMessages,
		conversationStatus,
	} = useSelector((state) => state.messages);
	const userId = useSelector((state) => state.user?._id);

	useEffect(() => {
		if (conversationStatus === "idle") {
			dispatch(fetchConversations());
			dispatch(fetchNotifications());
			dispatch(profil());
		}
	}, [dispatch, conversationStatus]);

	const handleConversationClick = (conversation) => {
		dispatch(setCurrentConversation(conversation));
		dispatch(fetchMessages(conversation._id));
		setActiveConversationId(conversation._id);
	};

	const openDeleteConversationModal = (e, conversation) => {
		const rect = e.currentTarget.getBoundingClientRect();
		setDeleteConversationModalPosition({
			top: rect.bottom + window.scrollY,
			left: rect.right + window.scrollX,
		});
		dispatch(setCurrentConversation(conversation));
		setIsDeleteConversationModalOpen(true);
	};


	useEffect(() => {
		if (currentConversation?._id) {
			dispatch(fetchMessages(currentConversation._id));
			setActiveConversationId(currentConversation._id);
		}
	}, [currentConversation, dispatch]);

	useEffect(() => {
		if (endOfMessagesRef.current) {
			endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [currentConversation]);

	const handleSendMessage = async () => {
		if (!message || !currentConversation) return;

		const messageData = {
			senderId: userId,
			conversationId: currentConversation._id,
			content: message,
		};

		try {
			await dispatch(sendMessage(messageData)).unwrap();
			await dispatch(fetchConversations()).unwrap();
			setMessage("");
		} catch (error) {
			console.error("Erreur lors de l'envoi du message:", error);
		}
	};

	const handleUpdateMessage = async () => {
		if (!editingMessageId || !editingMessageContent) return;

		try {
			await dispatch(
				updateMessage({ messageId: editingMessageId, content: editingMessageContent })
			).unwrap();
			setEditingMessageId(null);
			setEditingMessageContent("");
			closeMessageModal();
			setIsEditing(false);
		} catch (error) {
			console.error("Erreur lors de la mise à jour du message:", error);
		}
	};

	const handleDeleteMessage = async () => {
		if (!selectedMessageId || !currentConversation?._id) return;

		try {
			await dispatch(
				deleteMessage({ conversationId: currentConversation._id, messageId: selectedMessageId })
			).unwrap();
			closeMessageModal();
		} catch (error) {
			console.error("Erreur lors de la suppression du message:", error);
		}
	};

	const handleDeleteConversation = async () => {
		if (!currentConversation?._id) return;

		try {
			await dispatch(deleteConversation(currentConversation._id)).unwrap();
			setIsDeleteConversationModalOpen(false);
		} catch (error) {
			console.error("Erreur lors de la suppression de la conversation:", error);
		}
	};

	const toggleEmojiPicker = () => setIsEmojiPickerOpen(!isEmojiPickerOpen);
	const handleEmojiSelect = (emoji) => setMessage((prevMessage) => prevMessage + emoji.native);

	const openModal = (e) => {
		setModalPosition(getElementPosition(e.currentTarget));
		setIsModalOpen(true);
	};

	const openMessageModal = (e, messageId, content) => {
		setMessageModalPosition(getElementPosition(e.currentTarget));
		setSelectedMessageId(messageId);
		setEditingMessageContent(content);
		setIsMessageModalOpen(true);
	};

	const getElementPosition = (element) => {
		const rect = element.getBoundingClientRect();
		return { top: rect.bottom + window.scrollY, left: rect.right + window.scrollX };
	};

	const closeMessageModal = () => {
		setIsMessageModalOpen(false);
		setSelectedMessageId(null);
	};

	const formatTime = (isoString) => {
		const date = new Date(isoString);
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	};

	return (
		<div className="messages-container">
			<div className="menu-messages">
				<div className="conversation-title">
					<div className="messagerie">Messagerie</div>
					<div className="notifications">
						<IoArrowUndoOutline />
					</div>
					<div className="nouvelle-conversation">
						<button onClick={openModal}>
							<img src={plus} alt="Nouvelle conversation" />
						</button>
					</div>
				</div>
				<div className="conversation-search">
					<input type="text" placeholder="Search chats" />
				</div>
				<div className="conversation-list">
					{conversations.length > 0 ? (
						conversations.map((conversation) => {
							const lastMessage = conversation.messages?.slice(-1)[0];
							return (
								<div
									key={conversation._id}
									className={`conversation-card ${conversation._id === activeConversationId ? "conversation-active" : ""}`}
									onClick={() => handleConversationClick(conversation)}
									onContextMenu={(e) => openDeleteConversationModal(e, conversation)}
								>
									<div className="conversation-pictures">
										<img src={conversation.otherUserPhoto || photodefault} alt="User" />
									</div>
									<div className="conversation-text-container">
										<div className="conversation-text">
											<h2>{conversation.name}</h2>
											<p>{lastMessage?.content || "Aucun message envoyé"}</p>
										</div>
										<div className="conversation-time">
											{lastMessage ? formatTime(lastMessage.createdAt) : ""}
										</div>
									</div>
								</div>
							);
						})
					) : (
						<div>Aucune conversation</div>
					)}
				</div>
			</div>

			<div className="conversation-messages">
				<div className="conversation-chat">
					{currentConversation && conversationMessages[currentConversation._id]?.messages.length > 0 ? (
						conversationMessages[currentConversation._id].messages.map((msg) => (
							<div
								key={msg._id}
								className={`message ${msg.senderId === userId ? "message-sent" : "message-received"}`}
							>
								{isEditing && editingMessageId === msg._id ? (
									<MessageEditor
										editingMessageContent={editingMessageContent}
										setEditingMessageContent={setEditingMessageContent}
										handleUpdateMessage={handleUpdateMessage}
										cancelEdit={() => setIsEditing(false)}
									/>
								) : (
									<p onClick={(e) => openMessageModal(e, msg._id, msg.content)}>
										{msg.content === "Message supprimé" ? <span className="message-deleted">Message supprimé</span> : msg.content}
									</p>
								)}
							</div>
						))
					) : (
						<div className="select-conv">Sélectionnez une conversation pour voir les messages</div>
					)}
				</div>
				{currentConversation && (
					<div className="message-input">
						<button onClick={toggleEmojiPicker}>😊</button>
						{isEmojiPickerOpen && (
							<div className="picker">
								<Picker data={data} onEmojiSelect={handleEmojiSelect} previewPosition="none" emojiSize={24} theme="auto" />
							</div>
						)}
						<input
							type="text"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
							placeholder="Tapez votre message..."
						/>
						<button onClick={handleSendMessage}>
							<img src={send} alt="Envoyer" />
						</button>
					</div>
				)}
			</div>

			<div className="profile-messages">
				<ProfileSidebar
					currentConversation={currentConversation}
					navigate={navigate}
				/>
			</div>

			{isModalOpen && (
				<div className="create-conversation-modal" style={modalPosition}>
					<CreateConversationModal onClose={() => setIsModalOpen(false)} />
				</div>
			)}

			{isMessageModalOpen && (
				<div className="message-modal" style={messageModalPosition}>
					<MessageModal
						onDelete={handleDeleteMessage}
						onClose={closeMessageModal}
						onEdit={() => setIsEditing(true)}
						messageId={selectedMessageId}
						messageContent={selectedMessageContent}
					/>
				</div>
			)}

			{isDeleteConversationModalOpen && (
				<div className="delete-conversation-modal" style={modalPosition}>
					<button onClick={handleDeleteConversation}>Supprimer la conversation</button>
					<button onClick={() => setIsDeleteConversationModalOpen(false)}>Annuler</button>
				</div>
			)}
		</div>
	);
};

const MessageEditor = ({ editingMessageContent, setEditingMessageContent, handleUpdateMessage, cancelEdit }) => (
	<div className="edit-container">
		<div className="edit-container-buttons">
			<button onClick={handleUpdateMessage}>
				<MdCheck style={{ color: "green", fontSize: "20px" }} />
			</button>
			<button onClick={cancelEdit}>
				<MdClose style={{ color: "red", fontSize: "24px" }} />
			</button>
		</div>
		<textarea
			value={editingMessageContent}
			onChange={(e) => setEditingMessageContent(e.target.value)}
			rows="3"
			style={{ resize: "none" }}
		/>
	</div>
);

const ProfileSidebar = ({ currentConversation, navigate }) => (
	<>
		<div className="profile-picture">
			<img src={currentConversation?.otherUserPhoto || photodefault} alt="Profile" />
		</div>
		<div className="profile-author">
			<h4>{currentConversation?.name}</h4>
		</div>
		<div className="profile-conversation-members">
			{currentConversation?.users?.map((user) => (
				<h4 key={user._id}>{user.username},</h4>
			))}
		</div>
		<div className="profile-activity">
			<p>En ligne il y à 17 minutes</p>
		</div>
		<div className="profile-button">
			<button onClick={() => navigate(`/home/profile/${currentConversation?.otherUserId}`)}>
				Voir le profil
			</button>
		</div>
		<div className="profile-buttons">
			<img src={searchIcon} alt="Search" />
			<p>Search Chat</p>
		</div>
		<div className="profile-buttons">
			<img src={mediaIcon} alt="Médias" />
			<p>Médias</p>
		</div>
		<div className="profile-buttons">
			<img src={moreOptionsIcon} alt="Options" />
			<p>Plus d'options</p>
		</div>
	</>
);

export default Messaging;
