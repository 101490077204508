import React from 'react';
import useECharts from './useECharts';

const Chart = ({ options }) => {
	const chartRef = useECharts(options);

	return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default Chart;
