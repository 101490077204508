import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AdminProjectModal.css";
import { MdClose, MdCheck } from "react-icons/md";
import { FaMessage } from "react-icons/fa6";
import {
	acceptCandidate,
	declineCandidate,
	deleteMemberFromProject,
	fetchProjectById,
} from "../../redux/projectSlice";

const AdminProjectModal = ({ onClose }) => {
	const dispatch = useDispatch();

	const project = useSelector((state) => state.projects.project);

	const handleAcceptCandidate = async (candidateId) => {
		await dispatch(
			acceptCandidate({ projectId: project._id, userId: candidateId })
		).unwrap();

		dispatch(fetchProjectById(project._id));
	};

	const handleDeleteMember = async (memberId) => {
		await dispatch(
			deleteMemberFromProject({ projectId: project._id, userId: memberId })
		).unwrap();

		dispatch(fetchProjectById(project._id));
	};

	const handleDeclineCandidate = async (candidateId) => {
		await dispatch(
			declineCandidate({ projectId: project._id, userId: candidateId })
		).unwrap();

		dispatch(fetchProjectById(project._id));
	};

	const handleCloseAdminModal = () => {
		onClose();
	};

	if (!project) return null;

	return (
		<div className="admin-project-container">
			<h1>Administrer votre projet</h1>
			<div className="admin-project-content">
				<div className="candidate">
					<p>Inscriptions au projet</p>
					{project.inscriptions.map((membre) => (
						<div key={membre._id} className="member-card">
							<div className="member-card-left">
								<img
									src={membre.photo_profil}
									alt="photo de profil du membre"
								/>
								<p>{membre.username}</p>
							</div>
							<div className="member-card-right">
								<button>
									<FaMessage
										style={{
											color: "blue",
											fontSize: "20px",
											fontWeight: "500",
										}}
									/>
								</button>
								<button>
									{" "}
									<MdCheck
										onClick={() => handleAcceptCandidate(membre._id)}
										style={{
											color: "green",
											fontSize: "20px",
											fontWeight: "500",
										}}
									/>
								</button>
								<button>
									<MdClose
										onClick={() => handleDeclineCandidate(membre._id)}
										style={{
											color: "red",
											fontSize: "20px",
											fontWeight: "500",
										}}
									/>
								</button>
							</div>
						</div>
					))}
				</div>
				<div className="members">
					<p>Membres du projet</p>
					{project.inscrits.map((membre) => (
						<div key={membre._id} className="member-card">
							<div className="member-card-left">
								<img
									src={membre.photo_profil}
									alt="photo de profil du membre"
								/>
								<p>{membre.username}</p>
							</div>
							<div className="member-card-right">
								<button>
									<MdClose
										onClick={() => handleDeleteMember(membre._id)}
										style={{
											color: "red",
											fontSize: "20px",
											fontWeight: "500",
										}}
									/>
								</button>
							</div>
						</div>
					))}
				</div>
				<div className="project-other-options"></div>
			</div>
			<div className="close">
				<span onClick={handleCloseAdminModal}> &times;</span>
			</div>
		</div>
	);
};

export default AdminProjectModal;
