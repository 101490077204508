import React, { useEffect, useState } from "react";
import defaultProfile from "../../assets/images/photodefault.svg";
import "./ProjectCommentaires.css";
import close from "../../assets/images/fermer.svg";
import { useDispatch, useSelector } from "react-redux";
import { use } from "echarts";
import {
	fetchProjectById,
	fetchProjectComments,
	responseToCommentaire,
} from "../../redux/projectSlice";
import { useParams } from "react-router-dom";

const ProjectCommentaires = ({ commentaires, onClose }) => {
	const dispatch = useDispatch();
	const projectId = useParams().projectId;
	const userCommentaire = useSelector((state) => state.projects.commentaires);
	const status = useSelector((state) => state.projects.status);
	const error = useSelector((state) => state.projects.error);

	const [response, setResponse] = useState({});
	const [showResponse, setShowResponse] = useState({});
	const [newResponse, setNewResponse] = useState({});

	useEffect(() => {
	}, [showResponse]);

	const handleResponseChange = (commentId, value) => {
		setResponse({ ...response, [commentId]: value });
	};

	const handleNewResponseChange = (commentId, value) => {
		setNewResponse({ ...newResponse, [commentId]: value });
	};

	const handleResponseSubmit = async (projectId, commentId) => {
		const responseContent = newResponse[commentId];
		const responseData = {
			content: responseContent,
		};

		try {
			await dispatch(
				responseToCommentaire({
					projectId,
					commentId,
					responseData: { content: responseContent },
				})
			).unwrap();

			dispatch(fetchProjectById(projectId)).unwrap();
		} catch (error) {
			console.error("Erreur:", error);
		}

		setNewResponse((prev) => ({ ...prev, [commentId]: "" }));
	};

	const handleShowResponses = (commentId) => {
		setShowResponse((prev) => ({ ...prev, [commentId]: !prev[commentId] }));
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<div className="project-commentaires">
			<h2>Toutes les réactions</h2>
			{commentaires.length > 0 ? (
				commentaires.map((commentaire) => (
					<div key={commentaire._id} className="comment-card">
						<div className="comment-author">
							<img
								src={
									userCommentaire.utilisateur_id?.photo_profil || defaultProfile
								}
								alt="Profil utilisateur"
							/>
							<h3>
								{userCommentaire.utilisateur_id?.username || "Chargement..."}
							</h3>
						</div>
						<div className="comment-content">
							<div className="comment-content-content">
								<p>{commentaire.content}</p>
							</div>
							<div className="comment-date">
								<p>
									Le {new Date(userCommentaire.date_debut).toLocaleDateString()}{" "}
									à{" "}
									{new Date(userCommentaire.date_debut).toLocaleTimeString([], {
										hour: "2-digit",
										minute: "2-digit",
									})}
								</p>
							</div>

							<div className="response-input">
								<input
									type="text"
									value={newResponse[commentaire._id] || ""}
									onChange={(e) =>
										handleNewResponseChange(commentaire._id, e.target.value)
									}
									placeholder="Répondez ici..."
								/>
								<button
									onClick={() =>
										handleResponseSubmit(projectId, commentaire._id)
									}
								>
									Envoyer
								</button>
							</div>
							<button onClick={() => handleShowResponses(commentaire._id)}>
								{commentaire.responses?.length || 0} réponses
							</button>
							{showResponse[commentaire._id] && (
								<div className="responses">
									{commentaire.responses?.map((response) => (
										<div key={response._id} className="response">
											<div className="response-author">
												<img
													src={response.userId.photo_profil}
													alt="Profil utilisateur"
												/>
												<h3>{response.userId.username}</h3>
											</div>
											<div className="response-content">
												<p>{response.content}</p>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				))
			) : (
				<p>Aucun commentaire</p>
			)}
			<span className="close-button" onClick={handleClose}>
				&times;
			</span>
		</div>
	);
};

export default ProjectCommentaires;
