import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUserById, fetchUserPublications, SubscribeToUser, toggleEditMode, updateUserProfile } from "../../redux/userSlice";
import "./ProfileUser.css";
import PostCard from "../PostCard/PostCard";
import { FaSave, FaSearch } from "react-icons/fa";
import CreatePost from "../../Components/CreatePost/CreatePost";
import { HiOutlineArrowSmLeft } from "react-icons/hi";


const ProfileUser = () => {
	const { id } = useParams();
	console.log("ID", id);
	const isEditing = useSelector((state) => state.user.editMode);
	const dispatch = useDispatch();
	const { user: user, status: userStatus, error: error } = useSelector((state) => state.user.otherUser);
	const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [activeTab, setActiveTab] = useState("posts"); // Onglet actif (posts ou projets)
	const [searchTerm, setSearchTerm] = useState(""); // État pour la barre de recherche des posts
	const [searchProjectTerm, setSearchProjectTerm] = useState(""); // État pour la barre de recherche des projets

	useEffect(() => {
		if (userStatus === "idle" || user?._id !== id) {
			dispatch(fetchUserById(id));
			console.log("FETCHING USER PUBLICATIONS");
		}
	}, [dispatch, userStatus, isEditing]);

	if (userStatus === "loading") {
		return <p>Loading...</p>;
	}

	if (userStatus === "failed") {
		return <p>{error}</p>;
	}

	if (user == null) return <p>Attention</p>

	const handleOpenCreatePostModal = () => {
		setIsCreatePostModalOpen(true);
	};

	const handleCloseCreatePostModal = () => {
		setIsCreatePostModalOpen(false);
	};

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	const handleFollow = () => {
		dispatch(SubscribeToUser({
			targetUserId: user._id,
			subscribe: true
		}));
	};


	return (
		<div className="profile-container">

			<button className="back-profil-button" onClick={() => window.history.back()}>
				<HiOutlineArrowSmLeft />
			</button>

			{/* Profile Banner Section */}
			<div className="profile-banner-profile">
				<div className="banner-image">
					<img src={user.banner} alt="Profile banner" className="profile-banner-img" />
				</div>
				<div className="profile-picture-min">
					<img src={user.photo_profil} alt="profile" className="profile-picture" />
				</div>
			</div>

			{/* Profile Info Section */}
			<div className="profile-content">
				<div className="profile-header">
					<h2>{user.username}</h2>
					<p className="user-job-title">{user.jobTitle || "Artiste multidisciplinaire"}</p>
					<p className="user-location">{user.location || "Localisation non spécifiée"}</p>
				</div>

				<div className="profile-stats">
					<p>{user.abonne?.length || 0} <span>Abonné(e)s</span></p>
					<p>{user.abonnements?.length || 0} <span>Abonnements</span></p>
					<p>{user.relations?.length || 0} <span>Relations</span></p>
				</div>

				{/* Profile Buttons Section */}
				<div className="profile-buttons">
					<button className="follow-button" onClick={() => handleFollow()}
					>Suivre</button>
					<button className="follow-button">Message</button>
					<button className="follow-button">Lien</button>
				</div>
			</div>


			{/* Bio Section */}
			<div className="bio-section">
				<div>
					<h3>À propos</h3>
					<p>{user.bio || "Aucune bio disponible pour le moment."}</p>
				</div>
			</div>

			{/* Posts Section */}
			{
				activeTab === "posts" && (
					<div className="posts-section">
						{/* Tabs Section */}
						<div className="profile-tabs">
							<button
								className={`tab-button ${activeTab === "posts" ? "active" : ""}`}
								onClick={() => handleTabChange("posts")}
							>
								Publications
							</button>
							<button
								className={`tab-button ${activeTab === "projects" ? "active" : ""}`}
								onClick={() => handleTabChange("projects")}
							>
								Projets
							</button>
						</div>
						<div className="search-bar">
							<FaSearch className="search-icon" />
							<input
								type="text"
								placeholder="Rechercher des posts..."
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
						</div>
						<div className="posts-map-profile">
							{user?.posts?.filter((post) => post.titre?.toLowerCase().includes(searchTerm.toLowerCase())).length > 0 ? (
								user.posts.filter((post) => post.titre?.toLowerCase().includes(searchTerm.toLowerCase())).map((post) => (
									<div className="postCards-profile" key={post._id}>
										<PostCard className="profile-video" post={post} data={user.posts} />
									</div>
								))
							) : (
								<p>Aucune publication trouvée.</p>
							)}
						</div>
					</div>
				)
			}

			{/* Projects Section */}
			{
				activeTab === "projects" && (
					<div className="projects-section">
						{/* Tabs Section */}
						<div className="profile-tabs">
							<button
								className={`tab-button ${activeTab === "posts" ? "active" : ""}`}
								onClick={() => handleTabChange("posts")}
							>
								Publications
							</button>
							<button
								className={`tab-button ${activeTab === "projects" ? "active" : ""}`}
								onClick={() => handleTabChange("projects")}
							>
								Projets
							</button>
						</div>
						<div className="search-bar">
							<FaSearch className="search-icon" />
							<input
								type="text"
								placeholder="Rechercher des projets..."
								value={searchProjectTerm}
								onChange={(e) => setSearchProjectTerm(e.target.value)}
							/>
						</div>
						<div className="projects-map-profile">
							{user.projects?.filter((project) => project.title?.toLowerCase().includes(searchProjectTerm.toLowerCase())).length > 0 ? (
								user.projects.filter((project) => project.title?.toLowerCase().includes(searchProjectTerm.toLowerCase())).map((project) => (
									<div className="projectCards-profile" key={project._id}>
										<div className="project-item">
											<h4>{project.title}</h4>
											<p>{project.description}</p>
										</div>
									</div>
								))
							) : (
								<p>Aucun projet trouvé.</p>
							)}
						</div>
					</div>
				)
			}

			{/* Modal for creating post */}
			{
				isCreatePostModalOpen && (
					<div className="modal">
						<div className="modal-upload-content">
							<CreatePost handleCloseCreatePostModal={handleCloseCreatePostModal} />
						</div>
					</div>
				)
			}
		</div >
	);
};

export default ProfileUser;
