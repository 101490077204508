import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdSearch } from "react-icons/io";
import { FaPlus, FaImage, FaVideo, FaPen, FaTimes } from "react-icons/fa";
import {
	setCategorie,
	reset,
	setQuery,
	setFilters,
	fetchPublications,
	resetFeedItems,
} from "../../redux/publicationSlice";
import "./Publications.css";
import CreatePost from "../../Components/CreatePost/CreatePost";
import FillProfil from "../../Components/FillProfil/FillProfil";
import { profil } from "../../redux/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import Feed from "../Feed/Feed";
import Post from "../Post/Post";
import SelectBar from "../../Components/SelectBar/SelectBar";
import FilterSidebar from "../../Components/FilterSidebar/FilterSidebar";
import { subCategories } from "./subCategories";

const Publications = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [openFilter, setOpenFilter] = useState(true);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);
	const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
	const [isFillProfilModalOpen, setIsFillProfilModalOpen] = useState(false);
	const isFillProfilModalShown = useRef(localStorage.getItem("fillProfilModalShown"));
	const [searchTerm, setSearchTerm] = useState("");
	const selectedCategory = useSelector((state) => state.publications.feed.filters.categorie);
	const { user, profileCompleted } = useSelector((state) => state.user);
	const navigate = useNavigate();


	useEffect(() => {
		dispatch(profil());
	}, [dispatch]);

	useEffect(() => {
		if (user && !profileCompleted && !isFillProfilModalShown.current) {
			setIsFillProfilModalOpen(true);
			localStorage.setItem("fillProfilModalShown", "true");
			isFillProfilModalShown.current = "true";
		}
	}, [user, profileCompleted]);

	const handleCategoryClick = (category) => {
		dispatch(resetFeedItems());
		dispatch(setCategorie(category));
		dispatch(fetchPublications());
		setIsDropdownOpen(false);
	};

	const handleSearch = (e) => {
		e.preventDefault();
		dispatch(resetFeedItems());
		dispatch(setQuery(searchTerm));
		dispatch(fetchPublications());
	};

	const handleResetSearch = () => {
		setSearchTerm("");
		dispatch(setQuery(""));
		dispatch(resetFeedItems());
		dispatch(fetchPublications());
	};

	return (
		<div className="scroll-container">
			<div className="searchbar-publications">
				<form className="searchbar-container" onSubmit={handleSearch}>
					<div className="menu-item-container">
						<div
							className="menu-item"
							onMouseEnter={() => setIsDropdownOpen(true)}
							onMouseLeave={() => setIsDropdownOpen(false)}
						>
							<div className="menu-button">
								{selectedCategory ? selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1) : "Categories"}
							</div>
							{isDropdownOpen && (
								<div className="dropdown-content">
									{Object.keys(subCategories).map((category) => (
										<div
											key={category}
											className="dropdown-item"
											onClick={() => handleCategoryClick(category)}
										>
											{category.charAt(0).toUpperCase() + category.slice(1)}
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div className="input-wrapper">
						<input
							type="text"
							value={searchTerm}
							onChange={(e) => e.key === "Enter" ? handleSearch(e) : setSearchTerm(e.target.value)}
							placeholder="Rechercher..."
						/>
						{searchTerm && (
							<FaTimes
								className="clear-icon"
								onClick={handleResetSearch}
							/>
						)}
					</div>
					<button type="submit" className="recherche" onClick={
						handleSearch
					}>
						<IoMdSearch />
					</button>
				</form>
				<div className="create-content-wrapper">
					<button
						type="button"
						className="create-button"
						// onClick={() => setIsCreateDropdownOpen(!isCreateDropdownOpen)}
						onMouseEnter={() => setIsCreateDropdownOpen(true)}
						onMouseLeave={() => setIsCreateDropdownOpen(false)}
					>
						<FaPlus />
						{isCreateDropdownOpen && (
							<div className="dropdown-menu">
								<div className="dropdown-item" onClick={() => navigate("/home/posts")}>
									<FaPen className="dropdown-icon" /> Create Post
								</div>
								<div className="dropdown-item" onClick={() => navigate("/home/projects")} >
									<FaImage className="dropdown-icon" /> Create Project
								</div>
								<div className="dropdown-item">
									<FaVideo className="dropdown-icon" /> Create Alert
								</div>
							</div>
						)}
					</button>
				</div>
			</div>

			{!id ? (
				<>
					{/* Barre de sélection */}
					<SelectBar options={subCategories[selectedCategory]} onSelect={(cat) => dispatch(setCategorie(cat))} />
					<div className="poten">
						{/* Sidebar */}
						{openFilter && <FilterSidebar />}
						<Feed />
					</div>
				</>
			) : (
				<Post />
			)}

			{isFillProfilModalOpen && (
				<div className="modal">
					<div className="modal-content">
						<button
							className="close-button"
							onClick={() => setIsFillProfilModalOpen(false)}
						>
							X
						</button>
						<FillProfil handleCloseFillProfilModal={() => setIsFillProfilModalOpen(false)} />
					</div>
				</div>
			)}
		</div>
	);
};

export default Publications;
