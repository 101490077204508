import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	createPublication,
	searchUserByUsername,
} from "../../redux/publicationSlice";
import { uploadFile, clearSearchResults } from "../../redux/publicationSlice";
import "./CreatePost.css";
import Plus from "../../assets/images/plus.svg";
import minus from "../../assets/images/minus.svg";

const publicationTypes = ["text", "image", "video", "audio"];
const publicationCategories = [
	"musique",
	"film",
	"livre",
	"jeu video",
	"application",
	"script",
	"autre",
];
const publicationVisibilities = ["public", "prive", "ami"];
const publicationStatuses = ["brouillon", "publie", "archive"];

const CreatePost = ({ handleCloseCreatePostModal }) => {
	const dispatch = useDispatch();
	const [titre, setTitre] = useState("");
	const [categorie, setCategorie] = useState("");
	const [description, setDescription] = useState("");
	const [type, setType] = useState("");
	const [contenu, setContenu] = useState("");
	const [visibility, setVisibility] = useState("");
	const [status, setStatus] = useState("");
	const [featuring, setFeaturing] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [file, setFile] = useState(null);
	const searchResults = useSelector((state) => state.publications.searchResults);
	const uploadStatus = useSelector((state) => state.publications.fileUpload.status);
	const uploadUrl = useSelector((state) => state.publications.fileUpload.url);

	const handleSearch = async (e) => {
		e.preventDefault();
		const results = await dispatch(searchUserByUsername(searchTerm)).unwrap();
	};

	const handleAddUser = (user) => {
		if (!featuring.some((u) => u._id === user._id)) {
			setFeaturing([...featuring, user]);
			dispatch(clearSearchResults());
		}
	};

	const handleRemoveUser = (userId) => {
		setFeaturing(featuring.filter((user) => user._id !== userId));
	};

	const handleFileChange = async (e) => {
		setFile(e.target.files[0]);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!titre || !categorie || !type) {
			alert("Titre, catégorie, et type sont requis !");
			return;
		}

		try {
			let fileUrl = contenu;
			if (file) {
				const resultAction = await dispatch(uploadFile(file)).unwrap();
				if (resultAction) {
					fileUrl = resultAction;
				} else {
					throw new Error("Erreur lors de l'upload du fichier");
				}
			}

			const publicationData = {
				titre,
				categorie,
				description,
				type,
				contenu: fileUrl,
				visibility,
				// status,
				// featuring,
			};


			const result = await dispatch(createPublication(publicationData));
			if (result.meta.requestStatus === "fulfilled") {
				setTitre("");
				setCategorie("");
				setDescription("");
				setType("");
				setContenu("");
				setVisibility("");
				// setStatus("");
				setFeaturing([]);
				setFile(null);
				handleCloseCreatePostModal();
			} else {
				throw new Error("Erreur lors de la création de la publication");
			}
		} catch (error) {
			console.error("Error creating post:", error);
			alert("Erreur : " + error.message);
		}
	};

	return (
		<div className="create-post-container">
			<h2>Créer une Publication</h2>
			<form onSubmit={handleSubmit}>
				<div>
					<label htmlFor="titre">Titre</label>
					<input
						type="text"
						id="titre"
						value={titre}
						onChange={(e) => setTitre(e.target.value)}
						required
					/>
				</div>
				<div>
					<label htmlFor="categorie">Catégorie</label>
					<select
						id="categorie"
						value={categorie}
						onChange={(e) => setCategorie(e.target.value)}
						required
					>
						<option value="">Sélectionner une catégorie</option>
						{publicationCategories.map((cat) => (
							<option key={cat} value={cat}>
								{cat}
							</option>
						))}
					</select>
				</div>
				<div>
					<label htmlFor="description">Description</label>
					<textarea
						id="description"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					></textarea>
				</div>
				<div>
					<label htmlFor="type">Type</label>
					<select
						id="type"
						value={type}
						onChange={(e) => setType(e.target.value)}
						required
					>
						<option value="">Sélectionner un type</option>
						{publicationTypes.map((t) => (
							<option key={t} value={t}>
								{t}
							</option>
						))}
						no
					</select>
				</div>
				<div>
					{/* <label htmlFor="content">Contenu</label>
          <textarea
            id="content"
            value={contenu}
            onChange={(e) => setContenu(e.target.value)}
            required
          ></textarea> */}
				</div>
				<div>
					<label htmlFor="file">Télécharger un fichier</label>
					<input type="file" id="file" onChange={handleFileChange} />
				</div>
				<div>
					<label htmlFor="visibility">Visibilité</label>
					<select
						id="visibility"
						value={visibility}
						onChange={(e) => setVisibility(e.target.value)}
					>
						<option value="">Sélectionner une visibilité</option>
						{publicationVisibilities.map((vis) => (
							<option key={vis} value={vis}>
								{vis}
							</option>
						))}
					</select>
				</div>
				<div>
					<label htmlFor="searchUser">Ajouter un utilisateur (featuring)</label>
					<div className="search-input-container">
						<input
							type="text"
							id="searchUser"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<button type="button" onClick={handleSearch}>
							Rechercher
						</button>
					</div>
					<ul className="search-users">
						{Array.isArray(searchResults) &&
							searchResults.map((user) => (
								<li key={user._id}>
									<div className="photo-username">
										<img src={user.photo_profil} alt={user.username} />
										<p>{user.username}</p>
									</div>
									<div className="add-button">
										<img
											onClick={() => handleAddUser(user)}
											src={Plus}
											alt="Ajouter"
										/>
									</div>
								</li>
							))}
					</ul>
				</div>
				<div>
					{featuring.length > 0 && "Utilisateurs ajoutés :"}
					<ul className="added-users">
						{featuring.map((user) => (
							<li key={user._id}>
								<div className="photo-username">
									<img src={user.photo_profil} alt={user.username} />
									<p>{user.username}</p>
								</div>
								<div className="minus-button">
									<img src={minus} onClick={() => handleRemoveUser(user._id)} />
								</div>
							</li>
						))}
					</ul>
				</div>
				<div className="submit-cancel">
					<button type="submit" id="submit">
						Créer
					</button>
					<button
						type="button"
						id="cancel"
						onClick={handleCloseCreatePostModal}
					>
						Annuler
					</button>
				</div>
			</form>
		</div>
	);
};

export default CreatePost;
