import React, { useState } from 'react';
import './SelectBar.css';
import { fetchPublications, resetFeedItems, setSubCategories } from '../../redux/publicationSlice';
import { useDispatch, useSelector } from 'react-redux';

const SelectBar = ({ options }) => {
	const dispatch = useDispatch();
	const subCategories = useSelector((state) => state.publications.feed.filters.subCategorie);
	const handleSelect = (option) => {
		dispatch(setSubCategories(option));
		dispatch(resetFeedItems());
		dispatch(fetchPublications());
	};

	return (
		<div className="select-bar">
			{options && options
				.sort((a, b) => subCategories?.includes(a) ? -1 : subCategories?.includes(b) ? 1 : 0)
				.map((option) => (
					<button
						key={option}
						className={`select-bar-option` + (subCategories?.includes(option) ? " selected" : "")}
						onClick={() => handleSelect(option)}>
						{option.charAt(0).toUpperCase() + option.slice(1)}
					</button>
				))
			}
		</div >
	);
};

export default SelectBar;
