import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MyAxios from "../Services/Interceptor";

export const createcollaboration = createAsyncThunk(
	"collaborations/createcollaboration",
	async (collaborationData, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/collaboration/creation", collaborationData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchcollaborations = createAsyncThunk(
	"collaborations/fetchcollaborations",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/collaboration/");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchCollaborations = createAsyncThunk(
	"collaborations/fetchCollaborations",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/collaboration/collaborations");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchcollaborationById = createAsyncThunk(
	"collaborations/fetchcollaboration",
	async (collaborationId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`/collaboration/${collaborationId}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const joincollaboration = createAsyncThunk(
	"collaborations/joincollaboration",
	async (collaborationId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(`/collaboration/${collaborationId}/register`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const acceptCandidate = createAsyncThunk(
	"collaborations/acceptCandidate",
	async ({ collaborationId, userId }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/collaboration/${collaborationId}/${userId}/member`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const deleteMemberFromcollaboration = createAsyncThunk(
	"collaborations/deleteMemberFromcollaboration",
	async ({ collaborationId, userId }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.delete(
				`/collaboration/${collaborationId}/${userId}/member`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const addCommentTocollaboration = createAsyncThunk(
	"collaborations/addCommentTocollaboration",
	async ({ collaborationId, commentData }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/collaboration/${collaborationId}/comment`,
				commentData
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchcollaborationComments = createAsyncThunk(
	"collaborations/fetchcollaborationComments",
	async (collaborationId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`/collaboration/${collaborationId}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const updatecollaboration = createAsyncThunk(
	"collaborations/updatecollaboration",
	async ({ collaborationId, collaborationData }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.patch(
				`/collaboration/${collaborationId}/update`,
				collaborationData
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const responseToCommentaire = createAsyncThunk(
	"collaborations/responseToComment",
	async ({ collaborationId, commentId, responseData }, { rejectWithValue }) => {
		try {
			const res = await MyAxios.post(
				`/collaboration/${collaborationId}/comment/${commentId}/response`,
				responseData
			);
			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const declineCandidate = createAsyncThunk(
	"collaborations/declineCandidate",
	async ({ collaborationId, userId }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.delete(
				`/collaboration/${collaborationId}/${userId}/decline`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const collaborationSlice = createSlice({
	name: "collaborations",
	initialState: {
		collaborations: [],
		collaborationError: null,
		collaboration: {},
		collaborationStatus: "idle",
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCollaborations.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchCollaborations.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaborations.push(action.payload);
			})
			.addCase(fetchCollaborations.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(createcollaboration.pending, (state) => {
				state.status = "loading";
			})
			.addCase(createcollaboration.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaborations.push(action.payload);
			})
			.addCase(createcollaboration.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchcollaborations.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchcollaborations.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaborations = action.payload;
			})
			.addCase(fetchcollaborations.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(joincollaboration.pending, (state) => {
				state.status = "loading";
			})
			.addCase(joincollaboration.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaborations.push(action.payload);
			})
			.addCase(joincollaboration.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchcollaborationById.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchcollaborationById.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaboration = action.payload;
				state.commentaires = action.payload.commentaires || [];
			})
			.addCase(fetchcollaborationById.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(addCommentTocollaboration.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addCommentTocollaboration.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaboration.commentaires.push(action.payload);
			})
			.addCase(addCommentTocollaboration.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(fetchcollaborationComments.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchcollaborationComments.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.commentaires = action.payload;
			})
			.addCase(fetchcollaborationComments.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(responseToCommentaire.pending, (state) => {
				state.status = "loading";
			})
			.addCase(responseToCommentaire.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaboration.commentaires.push(action.payload);
			})
			.addCase(responseToCommentaire.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(acceptCandidate.pending, (state) => {
				state.status = "loading";
			})
			.addCase(acceptCandidate.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaboration.inscrits.push(action.payload);
			})
			.addCase(acceptCandidate.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(deleteMemberFromcollaboration.pending, (state) => {
				state.status = "loading";
			})
			.addCase(deleteMemberFromcollaboration.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaboration.inscrits = state.collaboration.inscrits.filter(
					(member) => member._id !== action.payload
				);
			})
			.addCase(deleteMemberFromcollaboration.rejected, (state, action) => {
				state.status = "failed";
				state.error = action;
			})

			.addCase(declineCandidate.pending, (state) => {
				state.status = "loading";
			})
			.addCase(declineCandidate.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaboration.inscrits = state.collaboration.inscrits.filter(
					(member) => member._id !== action.payload
				);
			})
			.addCase(declineCandidate.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(updatecollaboration.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updatecollaboration.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.collaboration = action.payload;
			})
			.addCase(updatecollaboration.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
			});
	},
});

export default collaborationSlice.reducer;
