import React, { useState } from 'react';
import './ContactPage.css';
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import Footer from '../../Components/Footer/Footer';

const ContactPage = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		subject: '',
		message: ''
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Handle form submission (e.g., send data to backend or API)
		console.log('Form data:', formData);
	};

	return (
		<>
			<div className="contact-page">
				<button className="back-button" onClick={() => window.history.back()}>
					<HiOutlineArrowSmLeft />
				</button>
				<h1>Contactez-nous</h1>
				<p>Si vous avez des questions ou des demandes spécifiques, n'hésitez pas à nous contacter via le formulaire ci-dessous.</p>

				<form className="contact-form" onSubmit={handleSubmit}>
					<div className="form-group">
						<label htmlFor="name">Nom</label>
						<input
							type="text"
							id="name"
							name="name"
							value={formData.name}
							onChange={handleChange}
							placeholder="Votre nom complet"
							required
						/>
					</div>

					<div className="form-group">
						<label htmlFor="email">Email</label>
						<input
							type="email"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							placeholder="Votre adresse email"
							required
						/>
					</div>

					<div className="form-group">
						<label htmlFor="subject">Sujet</label>
						<input
							type="text"
							id="subject"
							name="subject"
							value={formData.subject}
							onChange={handleChange}
							placeholder="Sujet de votre message"
							required
						/>
					</div>

					<div className="form-group">
						<label htmlFor="message">Message</label>
						<textarea
							id="message"
							name="message"
							value={formData.message}
							onChange={handleChange}
							placeholder="Écrivez votre message ici..."
							required
						/>
					</div>

					<button type="submit" className="submit-button">Envoyer le message</button>
				</form>
			</div>
			<Footer></Footer>
		</>

	);
};

export default ContactPage;
