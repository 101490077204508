import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MyAxios from "../Services/Interceptor";
import axios from "axios";

// Fonctions transvasées du feedSlice
export const searchUserByUsername = createAsyncThunk(
	"publications/searchUserByUsername",
	async (username, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`/users/user/${username}`);
			return response.data;
		} catch (error) {
			alert("Aucun utilisateur trouvé");
			return rejectWithValue(error.response.data);
		}
	}
);

export const addUserToPublication = createAsyncThunk(
	"publications/addUserToPublication",
	async ({ publicationId, userId }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/feed/publication/${publicationId}`,
				{ userId }
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchPublication = createAsyncThunk(
	"publications/fetchPublication",
	async (id, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/feed/publication/" + id);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchFeed = createAsyncThunk(
	"publications/fetchFeed",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/feed/fil");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchTopPublications = createAsyncThunk(
	"publications/fetchTopPublications",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/feed/top-publications");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const likePublication = createAsyncThunk(
	"publications/likePublication",
	async (publicationId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/feed/publication/${publicationId}/like`
			);
			return { publicationId, likes: response.data.likes };
		} catch (error) {
			console.error("likePublication error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

export const unlikePublication = createAsyncThunk(
	"publications/unlikePublication",
	async (publicationId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/feed/publication/${publicationId}/unlike`
			);
			return { publicationId, likes: response.data.likes };
		} catch (error) {
			console.error("unlikePublication error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

export const createPublication = createAsyncThunk(
	"publications/createPublication",
	async (publicationData, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				"/feed/publication",
				publicationData,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data + error.response.data.message);
		}
	}
);

export const deletePublication = createAsyncThunk(
	"publications/deletePublication",
	async (publicationId, { rejectWithValue }) => {
		try {
			await MyAxios.delete(`/feed/publication/${publicationId}`);
			return publicationId;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const createComment = createAsyncThunk(
	"publications/createComment",
	async ({ publicationId, commentData }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post(
				`/feed/publication/${publicationId}/commentaire`,
				commentData
			);
			return { publicationId, comment: response.data };
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchPublications = createAsyncThunk(
	"publications/fetchPublications",
	async (_, { getState }) => {
		const state = getState();
		const { query, categorie, subCategorie, type, filters, cursor } = state.publications.feed.filters;
		const token = localStorage.getItem("token");
		const response = await MyAxios.get("/feed/publications",
			{
				params: {
					query,
					categorie,
					subCategorie,
					type,
					cursor,
				},
			});
		return response.data;
	}
);


export const deleteComment = createAsyncThunk(
	"publications/deleteComment",
	async ({ publicationId, commentId }, { rejectWithValue }) => {
		try {
			await MyAxios.delete(
				`/feed/publication/${publicationId}/commentaire/${commentId}`
			);
			return { publicationId, commentId };
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchPublicationsByIds = createAsyncThunk(
	"publications/fetchPublicationsByIds",
	async (publicationIds, { rejectWithValue }) => {
		try {
			const responses = await Promise.all(
				publicationIds.map((id) => MyAxios.get(`/feed/publication/${id}`))
			);
			return responses.map((response) => response.data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchPublicationsByUserId = createAsyncThunk(
	"publications/fetchPublicationsByUserId",
	async (userId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`/feed/publications/user/${userId}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const searchPublications = createAsyncThunk(
	"publications/searchPublications",
	async (query, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(
				`/feed/search/publications?query=${query}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const publicationSlice = createSlice({
	name: "publications",
	initialState: {
		feed: {
			items: [],
			status: "idle",
			error: null,
			hasMore: true,
			filters: {
				cursor: new Date().toISOString(),
				query: "",
				categorie: "musique",
				subCategorie: [],
				type: "",
				tags: [],
			},
		},
		post: {
			post: null,
			status: "idle",
			error: null,
		},
		search: {
			items: [],
			status: "idle",
			error: null,
		},
		topPublications: {
			items: [],
			status: "idle",
			error: null,
		},
		fileUpload: {
			status: "idle",
			url: null,
			error: null,
		},
	},
	reducers: {
		reset: (state) => {
			state.feed.items = [];
			state.feed.status = "idle";
			state.feed.error = null;
			state.feed.hasMore = true;
			state.feed.filters.query = "";
			state.feed.filters.cursor = new Date().toISOString();
			state.feed.filters = {
				categorie: "",
				subCategorie: "",
				type: "",
				tags: [],
			};
		},
		setQuery: (state, action) => {
			console.log("action.payload", action.payload);
			state.feed.filters.query = action.payload;
			state.feed.items = [];
			state.feed.filters.cursor = new Date().toISOString();
			state.feed.hasMore = true;
		},
		setTags: (state, action) => {
			if (state.feed.filters.tags.includes(action.payload)) {
				state.feed.filters.tags = state.feed.filters.tags.filter((tag) => tag !== action.payload);
			} else {
				state.feed.filters.tags = [...state.feed.filters.tags, action.payload];
			}
		},
		setFilters: (state, action) => {
			state.feed.filters = action.payload;
			state.feed.items = [];
			state.feed.hasMore = true;
			state.feed.filters.cursor = new Date().toISOString();
		},
		resetFeedItems: (state) => {
			state.feed.items = [];
			state.feed.hasMore = true;
			state.feed.filters.cursor = new Date().toISOString();
		},
		setCategorie: (state, action) => {
			state.feed.filters.categorie = action.payload;
			state.feed.filters.subCategorie = [];
		},
		setSubCategories: (state, action) => {
			if (state.feed.filters.subCategorie.includes(action.payload)) {
				state.feed.filters.subCategorie = state.feed.filters.subCategorie.filter(
					(subCategorie) => subCategorie !== action.payload
				);
			} else {
				state.feed.filters.subCategorie = [...state.feed.filters.subCategorie, action.payload];
			}
		},
		resetFeedStatus: (state) => {
			state.feed.status = "idle";
		},
		resetPostStatus: (state) => {
			state.post.status = "idle";
		},
		resetFileUpload: (state) => {
			state.fileUpload = {
				status: "idle",
				url: null,
				error: null,
			};
		},
		clearSearchResults: (state) => {
			state.search.items = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPublications.pending, (state) => {
				state.feed.status = "loading";
			})
			.addCase(fetchPublications.fulfilled, (state, action) => {
				state.feed.status = "succeeded";
				state.feed.items = [...state.feed.items, ...action.payload];

				if (action.payload.length > 0) {
					state.feed.filters.cursor = action.payload[action.payload.length - 1].date_creation;
				}

				state.feed.hasMore = action.payload.length >= 10;
				state.feed.status = "idle";
			})
			.addCase(fetchPublications.rejected, (state, action) => {
				state.feed.status = "failed";
				state.feed.error = action.error.message;
			})
			.addCase(searchUserByUsername.fulfilled, (state, action) => {
				state.search.items = Array.isArray(action.payload) ? action.payload : [action.payload];
			})
			.addCase(searchUserByUsername.rejected, (state, action) => {
				state.search.items = [];
				state.search.error = action.payload;
			})
			.addCase(addUserToPublication.fulfilled, (state, action) => {
				const { _id, featuring } = action.payload;
				const publication = state.feed.items.find((pub) => pub._id === _id);
				if (publication) {
					publication.featuring = featuring;
				}
			})
			.addCase(addUserToPublication.rejected, (state, action) => {
				state.feed.error = action.payload;
			})
			.addCase(likePublication.fulfilled, (state, action) => {
				const { publicationId, likes } = action.payload;
				const publication = state.feed.items.find((pub) => pub._id === publicationId);
				if (publication) {
					publication.likes = likes;
				}
			})
			.addCase(unlikePublication.fulfilled, (state, action) => {
				const { publicationId, likes } = action.payload;
				const publication = state.feed.items.find((pub) => pub._id === publicationId);
				if (publication) {
					publication.likes = likes;
				}
			})
			.addCase(fetchPublication.pending, (state) => {
				state.post.status = "loading";
			})
			.addCase(fetchPublication.fulfilled, (state, action) => {
				state.post.status = "succeeded";
				state.post.post = action.payload;
			})
			.addCase(fetchPublication.rejected, (state, action) => {
				state.post.status = "failed";
				state.post.error = action.payload;
			})
			.addCase(fetchFeed.pending, (state) => {
				state.feed.status = "loading";
			})
			.addCase(fetchFeed.fulfilled, (state, action) => {
				state.feed.status = "succeeded";
				state.feed.items = action.payload;
			})
			.addCase(fetchFeed.rejected, (state, action) => {
				state.feed.status = "failed";
				state.feed.error = action.payload;
			})
			.addCase(fetchTopPublications.pending, (state) => {
				state.topPublications.status = "loading";
			})
			.addCase(fetchTopPublications.fulfilled, (state, action) => {
				state.topPublications.status = "succeeded";
				state.topPublications.items = action.payload;
			})
			.addCase(fetchTopPublications.rejected, (state, action) => {
				state.topPublications.status = "failed";
				state.topPublications.error = action.payload;
			})
			.addCase(createPublication.fulfilled, (state, action) => {
				state.feed.items.push(action.payload);
			})
			.addCase(deletePublication.fulfilled, (state, action) => {
				state.feed.items = state.feed.items.filter((pub) => pub._id !== action.payload);
			})
			.addCase(createComment.fulfilled, (state, action) => {
				const { publicationId, comment } = action.payload;
				const publication = state.feed.items.find((pub) => pub._id === publicationId);
				if (publication) {
					publication.commentaires.push(comment);
				}
			})
			.addCase(deleteComment.fulfilled, (state, action) => {
				const { publicationId, commentId } = action.payload;
				const publication = state.feed.items.find((pub) => pub._id === publicationId);
				if (publication) {
					publication.commentaires = publication.commentaires.filter(
						(comment) => comment._id !== commentId
					);
				}
			});
	},
});


export const {
	reset,
	setQuery,
	setFilters,
	resetFeedStatus,
	resetPostStatus,
	resetFeedItems,
	resetFileUpload,
	clearSearchResults,
	uploadFile,
	likePost,
	unsubscribeFromUser,
	setCategorie,
	setSubCategories,
	setTags,
} = publicationSlice.actions;

export default publicationSlice.reducer;
