import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import PostCard from "../PostCard/PostCard";
import { fetchPublications, reset, setQuery, setFilters } from "../../redux/publicationSlice";
import "./Feed.css";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import UserCard from "../../Components/UserCard/UserCard";

const Feed = () => {
	const dispatch = useDispatch();
	const { items, hasMore, cursor, status } = useSelector(
		(state) => state.publications.feed
	);
	const categories = useSelector((state) => state.publications.feed.filters.categorie);

	const [searchTerm, setSearchTerm] = useState("");
	const [selectedCategory, setSelectedCategory] = useState("");

	useEffect(() => {
		if (status === "idle") {
			fetchMoreData();
		}
	}, []);

	const fetchMoreData = () => {
		console.log("Fetching more data");
		if (status !== "loading" && hasMore) {
			dispatch(fetchPublications());
		}
	};


	const renderPost = (post) => {
		switch (categories) {
			case "user":
				return <UserCard key={post._id} user={post} className="user-card" />;
			case "projects":
				return <ProjectCard key={post._id} project={post} onJoinProject={() => { alert("yolo") }} />;
			default:
				return <PostCard key={post._id} post={post} />;
		}
	};


	return (
		<div className="infinite-scroll-container" id="scrollableDiv">
			<InfiniteScroll
				dataLength={items.length}
				next={fetchMoreData}
				hasMore={hasMore}
				loader={<h4></h4>}
				scrollThreshold={0.5}
				endMessage={<h4></h4>}
				scrollableTarget="scrollableDiv"
			>
				<div className="publications-grid">
					{items.map((post) => (
						renderPost(post)
					))}
				</div>
			</InfiniteScroll>
		</div >
	);
};

export default Feed;
