// webSocketMiddleware.js
import { addMessage, MessageReaded } from './messagesSlice';
import { RECEIVE_MESSAGE, SEND_MESSAGE, CONNECT_WEBSOCKET, DISCONNECT_WEBSOCKET, receiveMessage, readMessage } from './webSocketActions';

const webSocketMiddleware = store => {
	let socket = null;
	let reconnectInterval = null;
	let reconnectAttempts = 0;

	const connect = (token) => {
		socket = new WebSocket(`ws://Ws-service-env.eba-hbdrrj8j.us-east-1.elasticbeanstalk.com/?token=${token}`);
		// socket = new WebSocket(`wss://www.christopherloisel.com/?token=${token}`);

		socket.onopen = () => {
			reconnectAttempts = 0;
			clearReconnectInterval();
		};

		socket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			// store.dispatch(MessageReaded(data));

			if (data.type === 'RECEIVE_MESSAGE') {
				store.dispatch(addMessage({
					conversationId: data.payload.conversationId,
					message: data.payload,
				}));
			}

			if (data.type === "MESSAGE_READED") {
				store.dispatch(MessageReaded(data.payload));
			}
		};

		socket.onclose = () => {
			attemptReconnect(token);
		};

		socket.onerror = (error) => {
			console.error("WebSocket Error:", error);
			socket.close(); // Close the socket on error to trigger onclose
		};
	};

	const sendMessage = (type, payload) => {
		if (socket && socket.readyState === WebSocket.OPEN) {
			socket.send(JSON.stringify({ type, payload }));
		}
	};

	const attemptReconnect = (token) => {
		if (!reconnectInterval) {
			reconnectInterval = setInterval(() => {
				if (reconnectAttempts < 5) {
					reconnectAttempts += 1;
					connect(token);
				} else {
					clearReconnectInterval();
				}
			}, 5000); // Try to reconnect every 5 seconds
		}
	};

	const clearReconnectInterval = () => {
		if (reconnectInterval) {
			clearInterval(reconnectInterval);
			reconnectInterval = null;
		}
	};

	const close = () => {
		if (socket) {
			socket.close();
		}
		clearReconnectInterval();
	};

	return next => action => {
		switch (action.type) {
			case CONNECT_WEBSOCKET:
				const token = store.getState().auth.token;
				if (token) {
					connect(token);
				}
				break;

			case SEND_MESSAGE:
				sendMessage(action.payload.type, action.payload.payload);
				break;

			case DISCONNECT_WEBSOCKET:
				close();
				break;

			default:
				break;
		}

		return next(action);
	};
};

export default webSocketMiddleware;
