import "./Project.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addCommentToProject,
	clearProject,
	fetchProjectById,
	fetchProjectComments,
	joinProject,
	updateProject,
} from "../../redux/projectSlice";
import { Link, useParams } from "react-router-dom";
import bannertest from "../../assets/images/postfictifrock.webp";
import brouillon from "../../assets/images/brouillon2.png";
import defaultProfile from "../../assets/images/photodefault.svg";
import loadingImage from "../../assets/images/moreoptionsicon.svg";
import arrowback from "../../assets/images/arrowback.svg";
import ProjectCommentaires from "../../Components/ProjectCommentaires/ProjectCommentaires";
import { profil } from "../../redux/userSlice";
import { createConversation, sendMessage } from "../../redux/messagesSlice";
import AdminProjectModal from "../../Components/AdminProjectModal/AdminProjectModal";

const Project = () => {
	const { projectId } = useParams();
	const dispatch = useDispatch();
	const project = useSelector((state) => state.projects.project);
	const status = useSelector((state) => state.projects.status);
	const error = useSelector((state) => state.projects.error);
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [comment, setComment] = useState("");
	const [isNewComModalOpen, setIsNewComModalOpen] = useState(false);
	const commentaires = useSelector((state) => state.projects.commentaires);
	const [isJoinProjectModalOpen, setIsJoinProjectModalOpen] = useState(false);
	const userId = useSelector((state) => state.user.user?._id);
	const [candidateMessage, setCandidateMessage] = useState("");
	const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const projectCategory = [
		"musique",
		"film",
		"livre",
		"jeu video",
		"application",
		"script",
		"autre",
	];
	const [editedProject, setEditedProject] = useState({
		titre: project?.titre,
		description: project?.description,
		categorie: project?.categorie,
		visibility: project?.visibility,
		status: project?.status,
		progress: project?.progress,
		date_fin: project?.date_fin,
		date_debut: project?.date_debut,
		tags: [],
	});

	useEffect(() => {
		dispatch(clearProject());
		dispatch(fetchProjectById(projectId));
		dispatch(fetchProjectComments(projectId));
		dispatch(profil());
	}, [dispatch, projectId]);

	const handleImageLoaded = () => {
		setIsImageLoaded(true);
	};

	const handleSubmitComment = (e) => {
		e.preventDefault();
		if (comment.trim()) {
			dispatch(
				addCommentToProject({ projectId, commentData: { content: comment } })
			);
			setComment("");
			dispatch(fetchProjectComments(projectId));
		}
	};

	const handleCandidateMessageChange = (e) => {
		setCandidateMessage(e.target.value);
	};

	const handleJoinProject = async () => {
		try {
			const createdConversation = await dispatch(
				createConversation({
					usersIds: [project.utilisateur_id?._id, userId],
					groupName: project?.titre,
				})
			).unwrap();

			const conversationId = createdConversation._id;

			const messageData = {
				content: candidateMessage,
				senderId: userId,
				conversationId: conversationId,
			};

			await dispatch(sendMessage(messageData)).unwrap();

			await dispatch(joinProject(projectId)).unwrap();

			setIsJoinProjectModalOpen(false);
		} catch (error) {
			console.error("Erreur:", error);
		}
	};

	const handleJoinProjectModal = () => {
		setIsJoinProjectModalOpen(!isJoinProjectModalOpen);
	};

	const handleAdminModal = () => {
		setIsAdminModalOpen(!isAdminModalOpen);
	};

	const handleCloseAdminModal = () => {
		setIsAdminModalOpen(false);
	};

	const handleImageError = (e) => {
		e.target.src = defaultProfile;
	};

	const handleNewComModal = () => {
		setIsNewComModalOpen(!isNewComModalOpen);
	};

	const closeNewComModal = () => {
		setIsNewComModalOpen(false);
	};

	const handleSaveProject = async () => {
		try {
			await dispatch(
				updateProject({ projectId, projectData: editedProject })
			).unwrap();
			setIsEditing(false);
		} catch (error) {
			console.error("Erreur lors de la mise à jour du projet:", error);
		}

		dispatch(fetchProjectById(projectId));
	};

	if (!project) return null;

	return (
		<div className="project-container">
			{status === "succeeded" && project && (
				<div className="project-content">
					<div className="project-banner">
						<img src={bannertest} alt="Bannière du projet" />
					</div>
					<div className="project-title">
						<div className="project-title-infos">
							<div className="project-title-author">
								<img
									src={
										isImageLoaded
											? project.utilisateur_id?.photo_profil || defaultProfile
											: loadingImage
									}
									onLoad={handleImageLoaded}
									onError={handleImageError}
									alt="Auteur du projet"
								/>
								<p>{project.utilisateur_id?.username || "Chargement..."}</p>
							</div>

							<div className="project-title-category">
								{isEditing ? (
									<select
										value={editedProject.categorie}
										onChange={(e) =>
											setEditedProject({
												...editedProject,
												categorie: e.target.value,
											})
										}
									>
										{projectCategory.map((cat) => (
											<option key={cat} value={cat}>
												{cat}
											</option>
										))}
									</select>
								) : (
									<p>Projet de : {project.categorie}</p>
								)}
							</div>
						</div>
						<div className="project-title-title">
							{isEditing ? (
								<input
									type="text"
									placeholder={project.titre}
									value={editedProject.titre}
									onChange={(e) =>
										setEditedProject({
											...editedProject,
											titre: e.target.value,
										})
									}
								/>
							) : (
								<h2>{project.titre}</h2>
							)}
						</div>
					</div>
					<div className="project-description">
						<div className="project-description-content">
							<div className="project-description-title">
								<p>Description :</p>
							</div>
							<div className="project-description-description">
								{isEditing ? (
									<textarea
										placeholder={project.description}
										value={editedProject.description}
										onChange={(e) =>
											setEditedProject({
												...editedProject,
												description: e.target.value,
											})
										}
									/>
								) : (
									<p>{project.description}</p>
								)}
							</div>
						</div>
					</div>
					{/* <div className="project-progress">
            <div className="project-progress-title">
              <p>Progression :</p>
            </div>
            <div className="progress-bar"></div>
          </div> */}
					<div className="member-status-subscribe">
						{project && project.inscriptions?.length > 0 && (
							<div className="member">
								<p>Membres du projet :</p>
								{project.inscriptions.map((membre) => (
									<div key={membre._id} className="member-card">
										<img
											src={membre.photo_profil || defaultProfile}
											alt="photo de profil du membre"
										/>
										<p>{membre.username}</p>
									</div>
								))}
							</div>
						)}
						<div className="subscribe">
							<button onClick={handleJoinProjectModal}>
								<p>Créateurs, </p>
								<p>Qu'attendez-vous?</p>
								<p>Rejoignez le projet ! </p>
							</button>
							{isJoinProjectModalOpen && (
								<div className="modal-join-project">
									<div className="modal-join-project-title">
										<h3>
											Rejoindre le projet <br />"{project.titre}"
										</h3>
									</div>
									<div className="modal-join-project-content">
										<textarea
											value={candidateMessage}
											placeholder="Pourquoi souhaitez-vous rejoindre ce projet ?"
											onChange={handleCandidateMessageChange}
										></textarea>
										<div className="modal-join-project-buttons">
											<button onClick={handleJoinProject}>
												Envoyer ma candidature
											</button>
											<button onClick={handleJoinProjectModal}>Annuler</button>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="status">
							<p>Statut du projet : </p>
							<img src={brouillon} alt="progression du projet" />
							<p>{project.status}</p>
						</div>
					</div>
					<div className="project-add-commentaires">
						<div className="project-add-commentaires-title">
							<h3>Donnez votre avis :</h3>
							<div className="all-commentaires">
								<p onClick={handleNewComModal} onClose={closeNewComModal}>
									Voir toutes les réactions
								</p>
							</div>
						</div>
						<div className="project-add-commentaires-content">
							<form onSubmit={handleSubmitComment}>
								<input
									type="text"
									placeholder="Réagissez à votre tour !"
									value={comment}
									onChange={(e) => setComment(e.target.value)}
								/>
								<button type="submit">Envoyer</button>
							</form>
						</div>
					</div>
					<div className="button-back">
						<Link to="/projects">
							<button>
								<img src={arrowback} alt="Retour à la liste des projets" />
								<p>Liste des projets</p>
							</button>
						</Link>
					</div>
					{isNewComModalOpen && (
						<div className="modal-commentaires">
							<div className="modal-commentaires-content">
								<ProjectCommentaires
									onClose={closeNewComModal}
									commentaires={project.commentaires || []}
								/>
							</div>
						</div>
					)}
				</div>
			)}
			{userId === project.utilisateur_id?._id && (
				<div className="project-actions">
					<button onClick={handleAdminModal}>Membres</button>
					<button onClick={() => setIsEditing(!isEditing)}>
						{isEditing ? "Annuler" : "Modifier le projet"}
					</button>
				</div>
			)}
			{isEditing && (
				<div className="project-edit-buttons">
					<p>Bienvenu dans le mode édition</p>
					<div className="edit-buttons">
						<button onClick={handleSaveProject}>Confirmer</button>
						<button onClick={() => setIsEditing(false)}>Annuler</button>
					</div>
				</div>
			)}
			{isAdminModalOpen && (
				<div className="admin-project-modal">
					<AdminProjectModal
						onClose={() => handleCloseAdminModal}
						project={project}
					/>
				</div>
			)}
		</div>
	);
};

export default Project;
