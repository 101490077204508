import React from "react";
import "./ProjectCard.css";

const ProjectCard = ({ project, onJoinProject }) => {
	return (
		<div className="project-card">
			<div className="project-banner">
				<img src={project.banner} alt={`${project.titre} banner`} className="banner-image" />
			</div>

			<div className="project-info">
				<div className="project-avatar">
					<img
						src={project.utilisateur_id.photo_profil}
						alt={`${project.utilisateur_id.username} avatar`}
						className="avatar-image"
					/>
				</div>
				<h3 className="project-title">{project.titre}</h3>
				<p className="project-user">{project.utilisateur_id.username}</p>
				<p className="project-description">{project.description}</p>
				<p className="project-category">Catégorie : {project.categorie}</p>
				<p className="project-dates">
					Du {new Date(project.date_debut).toLocaleDateString()} au {new Date(project.date_fin).toLocaleDateString()}
				</p>
				<button className="join-button" onClick={onJoinProject}>Rejoindre le projet</button>
			</div>
		</div>
	);
};

export default ProjectCard;
