exports.subCategories = {
	musique: [
		"rap",
		"pop",
		"rock",
		"jazz",
		"classique",
		"électronique",
		"blues",
		"reggae",
		"folk",
		"soul",
		"métal",
		"R&B"
	],
	film: [
		"drame",
		"comédie",
		"action",
		"horreur",
		"science-fiction",
		"thriller",
		"animation",
		"documentaire",
		"fantastique",
		"biopic",
		"film noir"
	],
	livre: [
		"roman",
		"science-fiction",
		"biographie",
		"fantastique",
		"thriller",
		"polar",
		"essai",
		"histoire",
		"poésie",
		"manga",
		"BD"
	],
	"jeu video": [
		"action",
		"aventure",
		"RPG",
		"simulation",
		"stratégie",
		"FPS",
		"puzzle",
		"plateforme",
		"combat",
		"MMORPG",
		"survie",
		"course"
	],
	application: [
		"productivité",
		"éducation",
		"jeu",
		"communication",
		"finance",
		"santé",
		"musique",
		"photo et vidéo",
		"utilitaires",
		"voyage"
	],
	script: [
		"scénario",
		"sketch",
		"pièce de théâtre",
		"storyboard",
		"court métrage",
		"pub",
		"documentaire",
		"vidéo musicale",
		"film interactif"
	],
	user: [
		"spectateur",
		"artiste",
		"producteur",
	],
	projects: [
		"court-métrage",
		"long-métrage",
		"clip",
		"publicité",
		"album",
		"single",
		"livre",
		"jeu vidéo",
		"application",
		"site web",
		"scénario",
		"storyboard"
	]
};
