import React, { useEffect, useState } from "react";
import { FaUserCircle, FaUserEdit, FaLock, FaCog } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./UserMiniature.css";
import { toggleEditMode } from "../../redux/userSlice";
import { logout } from "../../redux/authSlice";

const UserMiniature = () => {
	const user = useSelector((state) => state.user.user);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isProfilePrivate, setIsProfilePrivate] = useState(user.user?.isPrivate || false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleDropdownToggle = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleOptionClick = (option) => {
		setIsDropdownOpen(false);
		switch (option) {
			case "profile":
				navigate("/home/profile");
				break;
			case "settings":
				navigate("/home/settings");
				break;
			case "edit-profile":
				dispatch(toggleEditMode());
				navigate("/home/profile")
				break;
			case "toggle-privacy":
				// dispatch(toggleProfilePrivacy());
				break;
			case "logout":
				dispatch(logout());
				localStorage.removeItem("token");
				navigate("/login");
				break;
			default:
				break;
		}
	};

	const toggleProfilePrivacy = () => {
		// Basculer la confidentialité du profil
		setIsProfilePrivate(!isProfilePrivate);
		alert(isProfilePrivate ? "Profil rendu public" : "Profil rendu privé");
	};

	return (
		<div className="user-miniature-container" onClick={handleDropdownToggle}>
			<div className="profile-icon">
				{user.user?.photo_profil ? (
					<img src={user.user.photo_profil} alt="User Profile" className="profile-image" />
				) : (
					<FaUserCircle className="default-profile-icon" />
				)}
			</div>
			{
				isDropdownOpen && (
					<div className="profile-dropdown-menu">
						<div className="dropdown-item" onClick={() => handleOptionClick("profile")}>
							<FaUserCircle /> Mon Profil
						</div>
						<div className="dropdown-item" onClick={() => handleOptionClick("edit-profile")}>
							<FaUserEdit />  Modifier le profil
						</div>
						<div className="dropdown-item" onClick={() => handleOptionClick("toggle-privacy")}>
							<FaLock />  {isProfilePrivate ? "Passer en public" : "Passer en privé"}
						</div>
						<div className="dropdown-item" onClick={() => handleOptionClick("settings")}>
							<FaCog />  Paramètres
						</div>
						<div className="dropdown-item" onClick={() => handleOptionClick("logout")}>
							<FaUserCircle />  Déconnexion
						</div>
					</div>
				)
			}
		</div >
	);
};

export default UserMiniature;
