import { useEffect, useRef } from "react";
import * as echarts from "echarts";

const useECharts = (options) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [options]);

  return chartRef;
};

export default useECharts;
