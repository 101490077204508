import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from 'react-icons/fa';  // Import de l'icône d'édition
import { updateUserProfile } from "../../redux/userSlice";
import "./FillProfil.css";
import artiste from "../../assets/images/artiste.png";
import viewer from "../../assets/images/viewer.png";
import producteur from "../../assets/images/producteur.png";
import evenement from "../../assets/images/evenement.png";
import { profil } from "../../redux/userSlice";

const FillProfilMultiSteps = ({ handleCloseFillProfilModal }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user.user);

	// États pour gérer les informations du profil
	const [step, setStep] = useState(1);
	const [role, setRole] = useState(user?.role || "spectateur");
	const [username, setUsername] = useState(user?.username || "");
	const [email, setEmail] = useState(user?.email || "");
	const [bio, setBio] = useState(user?.bio || "");
	const [profileImage, setProfileImage] = useState(null);
	const [bannerImage, setBannerImage] = useState(null);
	const [location, setLocation] = useState(user?.location || "");
	const [jobTitle, setJobTitle] = useState(user?.jobTitle || "");

	// États pour le chargement des images
	const [loadingProfileImage, setLoadingProfileImage] = useState(false);
	const [loadingBannerImage, setLoadingBannerImage] = useState(false);

	// États pour les erreurs de chargement des images
	const [profileImageError, setProfileImageError] = useState(false);
	const [bannerImageError, setBannerImageError] = useState(false);

	const handleRoleClick = (selectedRole) => {
		setRole(selectedRole);
		setStep(2); // Passer à l'étape suivante après le choix du rôle
	};

	const handleImageUpload = (e, type) => {
		if (type === "profileImage") {
			setProfileImage(e.target.files[0]);
			console.log("pp :", profileImage);
			setLoadingProfileImage(true);
			setProfileImageError(false);
		} else if (type === "bannerImage") {
			setBannerImage(e.target.files[0]);
			setLoadingBannerImage(true);
			setBannerImageError(false);
		}
	};

	const handleImageLoad = (type) => {
		if (type === "profileImage") {
			setLoadingProfileImage(false);
		} else if (type === "bannerImage") {
			setLoadingBannerImage(false);
		}
	};

	const handleImageError = (type) => {
		if (type === "profileImage") {
			setProfileImageError(true);
			setLoadingProfileImage(false);
		} else if (type === "bannerImage") {
			setBannerImageError(true);
			setLoadingBannerImage(false);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = new FormData();

		formData.append("username", username);
		formData.append("email", email);
		formData.append("role", role);
		formData.append("bio", bio);
		formData.append("location", location);
		formData.append("jobTitle", jobTitle);

		if (profileImage) {
			formData.append("profileImage", profileImage);
		}
		if (bannerImage) {
			formData.append("bannerImage", bannerImage);
		}

		console.log("FormData envoyé :", formData);

		dispatch(updateUserProfile(formData));
	};


	if (!user) {
		dispatch(profil());
		return <div>Chargement des informations...</div>;
	}

	return (
		<div className="fill-profil-container">
			<form onSubmit={handleSubmit} className="fillform">
				{step === 1 && (
					<div className="fillstep step-1">
						<h1>Choisissez votre voie :</h1>
						<div className="role-selection">
							<button
								type="button"
								className={role === "spectateur" ? "selected" : ""}
								onClick={() => handleRoleClick("spectateur")}
							>
								<img src={viewer} alt="Spectateur" className="buttonimg" />
								<span>Spectateur</span>
							</button>
							<button
								type="button"
								className={role === "artiste" ? "selected" : ""}
								onClick={() => handleRoleClick("artiste")}
							>
								<img src={artiste} alt="Artiste" className="buttonimg" />
								<span>Artiste</span>
							</button>
							<button
								type="button"
								className={role === "producteur" ? "selected" : ""}
								onClick={() => handleRoleClick("producteur")}
							>
								<img src={producteur} alt="Producteur" className="buttonimg" />
								<span>Producteur</span>
							</button>
							<button
								type="button"
								className={role === "lieu" ? "selected" : ""}
								onClick={() => handleRoleClick("lieu")}
							>
								<img src={evenement} alt="Lieu de représentation" className="buttonimg" />
								<span>Lieu de représentation</span>
							</button>
						</div>
					</div>
				)}

				{step === 2 && (
					<div className="step-2-container">
						<h1>Complétez votre profil</h1>
						<div className="profile-container">
							{/* Profile Banner Section */}
							<div className="profile-banner-profile">
								<div className="banner-image">
									<label htmlFor="bannerImageInput" className="edit-icon-button">
										<img
											src={bannerImage ? URL.createObjectURL(bannerImage) : user.banner}
											alt="Profile banner"
											className="profile-banner-img"
											onLoad={() => handleImageLoad("bannerImage")}
											onError={() => handleImageError("bannerImage")}
										/>
									</label>
									<input
										type="file"
										id="bannerImageInput"
										accept="image/*"
										className="hidden-input"
										onChange={(e) => handleImageUpload(e, "bannerImage")}
									/>
								</div>
								<div className="profile-picture-min">
									<label htmlFor="profileImageInput">
										<img
											src={profileImage ? URL.createObjectURL(profileImage) : user.photo_profil}
											alt="profile"
											className="edit-icon-button"
											onLoad={() => handleImageLoad("profileImage")}
											onError={() => handleImageError("profileImage")}
										/>
									</label>
									<input
										type="file"
										id="profileImageInput"
										accept="image/*"
										className="hidden-input"
										onChange={(e) => handleImageUpload(e, "profileImage")}
									/>
								</div>
							</div>

							{/* Profile Info Section */}
							<div className="profile-content">
								<div className="profile-header">
									<input
										type="text"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
										placeholder="Nom d'utilisateur"
									/>
								</div>

								<div className="profile-details">
									<input
										type="text"
										value={jobTitle}
										onChange={(e) => setJobTitle(e.target.value)}
										placeholder="Titre ou discipline artistique"
									/>

									<input
										type="text"
										value={location}
										onChange={(e) => setLocation(e.target.value)}
										placeholder="Localisation"
									/>
								</div>
							</div>

							{/* Bio Section */}
							<div className="bio-section">
								<h3>À propos</h3>
								<textarea
									value={bio}
									onChange={(e) => setBio(e.target.value)}
									placeholder="Votre biographie"
								/>
							</div>
						</div>

						<button onClick={handleSubmit} className="save-profile-btn">Enregistrer</button>
					</div>
				)}
			</form>
		</div>
	);
};

export default FillProfilMultiSteps;
