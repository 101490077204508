import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FaUserEdit, FaSave, FaSearch } from "react-icons/fa"; // Import des icônes
import { fetchUserById, fetchUserPublications, toggleEditMode, updateUserProfile } from "../../redux/userSlice";
import "./Profile.css";
import PostCard from "../PostCard/PostCard";
import CreatePost from "../../Components/CreatePost/CreatePost";

const Profile = () => {
	const isEditing = useSelector((state) => state.user.editMode);
	const dispatch = useDispatch();
	const { user: user, status: userStatus, error: error } = useSelector((state) => state.user.user);
	const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [activeTab, setActiveTab] = useState("posts"); // Onglet actif (posts ou projets)
	const [searchTerm, setSearchTerm] = useState(""); // État pour la barre de recherche des posts
	const [searchProjectTerm, setSearchProjectTerm] = useState(""); // État pour la barre de recherche des projets
	const [location, setLocation] = useState(user?.location || "");
	const [username, setUsername] = useState(user?.username || "");
	const [jobTitle, setJobTitle] = useState(user?.jobTitle || "");
	const [profileImage, setProfileImage] = useState(null);
	const [bannerImage, setBannerImage] = useState(null);
	const [bio, setBio] = useState(user?.bio || "");

	useEffect(() => {
		if (user._id && userStatus === "idle") {
			console.log("ID", user._id);
			dispatch(fetchUserById(user._id));
		}
		console.log("FETCHING USER PUBLICATIONS");
		dispatch(fetchUserPublications(user._id));
	}, [dispatch, user._id, userStatus, isEditing]);

	if (userStatus === "loading") {
		return <p>Loading...</p>;
	}

	if (userStatus === "failed") {
		return <p>{error}</p>;
	}

	if (!user) return null;

	const handleOpenCreatePostModal = () => {
		setIsCreatePostModalOpen(true);
	};

	const handleCloseCreatePostModal = () => {
		setIsCreatePostModalOpen(false);
	};

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	// Fonction pour enregistrer les modifications de profil
	const handleSaveProfile = () => {
		const formData = new FormData();

		formData.append("username", username);
		formData.append("bio", bio);
		formData.append("location", location);
		formData.append("jobTitle", jobTitle);

		if (profileImage) {
			formData.append("profileImage", profileImage);
		}
		if (bannerImage) {
			formData.append("bannerImage", bannerImage);
		}

		console.log("FormData envoyé :", formData);

		dispatch(updateUserProfile(formData));
		dispatch(toggleEditMode());
	};


	const handleImageUpload = (e, type) => {
		if (type === "profileImage") {
			setProfileImage(e.target.files[0]);
		} else if (type === "bannerImage") {
			setBannerImage(e.target.files[0]);
		}
	};


	return (
		<div className="profile-container">
			{/* Profile Banner Section */}
			<div className="profile-banner-profile">
				{!isEditing ? (
					<div className="banner-image">
						<img src={user.banner} alt="Profile banner" className="profile-banner-img" />
					</div>) : (
					<>
						<label htmlFor="bannerImageInput" className="edit-icon-button">
							<img
								src={bannerImage ? URL.createObjectURL(bannerImage) : user.banner}
								alt="Profile banner"
								className="profile-banner-img"
							/>
						</label>
						<input
							type="file"
							id="bannerImageInput"
							accept="image/*"
							className="hidden-input"
							onChange={(e) => handleImageUpload(e, "bannerImage")}
						/>
					</>
				)}
				<div className="profile-picture-min">
					{!isEditing ? (
						<img src={user.photo_profil} alt="profile" className="profile-picture" />
					) : (
						<>
							<label htmlFor="profileImageInput">
								<img
									src={profileImage ? URL.createObjectURL(profileImage) : user.photo_profil}
									alt="profile"
									className="profile-picture"
								/>
							</label>
							<input
								type="file"
								id="profileImageInput"
								accept="image/*"
								className="hidden-input"
								onChange={(e) => handleImageUpload(e, "profileImage")}
							/>
						</>
					)}
				</div>
			</div>

			{/* Profile Info Section */}
			<div className="profile-content">
				<div className="profile-header">
					{isEditing ? (
						<>
							<input
								type="text"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								placeholder="Nom d'utilisateur"
							/>
							<input
								type="text"
								value={jobTitle}
								onChange={(e) => setJobTitle(e.target.value)}
								placeholder="Titre ou discipline artistique"
							/>
							<input
								type="text"
								value={location}
								onChange={(e) => setLocation(e.target.value)}
								placeholder="Localisation"
							/>
						</>
					) : (
						<>
							<h2>{username}</h2>
							<p className="user-job-title">{jobTitle || "Artiste multidisciplinaire"}</p>
							<p className="user-location">{user.location || "Localisation non spécifiée"}</p>
						</>
					)}
				</div>

				<div className="profile-stats">
					<p>{user.abonne?.length || 0} <span>Abonné(e)s</span></p>
					<p>{user.abonnements?.length || 0} <span>Abonnements</span></p>
					<p>{user.relations?.length || 0} <span>Relations</span></p>
				</div>
			</div>

			{/* Bio Section */}
			<div className="bio-section">
				{isEditing ? (
					<div className="bio-edit">
						<textarea
							value={bio}
							onChange={(e) => setBio(e.target.value)}
							placeholder="Votre biographie"
						/>
					</div>
				) : (
					<div>
						<h3>À propos</h3>
						<p>{bio || "Aucune bio disponible pour le moment."}</p>
					</div>
				)}
			</div>

			{/* Mode édition toggle */}
			<div className={`edit-save-btns ${isEditing ? "editing" : ""}`}>
				{isEditing ? (
					<button onClick={handleSaveProfile} className="save-btn">
						<FaSave /> Enregistrer
					</button>
				) : (
					<></>
				)}
			</div>

			{/* Posts Section */}
			{
				activeTab === "posts" && (
					<div className="posts-section">
						{/* Tabs Section */}
						<div className="profile-tabs">
							<button
								className={`tab-button ${activeTab === "posts" ? "active" : ""}`}
								onClick={() => handleTabChange("posts")}
							>
								Publications
							</button>
							<button
								className={`tab-button ${activeTab === "projects" ? "active" : ""}`}
								onClick={() => handleTabChange("projects")}
							>
								Projets
							</button>
						</div>
						<div className="search-bar">
							<FaSearch className="search-icon" />
							<input
								type="text"
								placeholder="Rechercher des posts..."
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
						</div>
						<div className="posts-map-profile">
							{user.posts.filter((post) => post.titre?.toLowerCase().includes(searchTerm.toLowerCase())).length > 0 ? (
								user.posts.filter((post) => post.titre?.toLowerCase().includes(searchTerm.toLowerCase())).map((post) => (
									<div className="postCards-profile" key={post._id}>
										<PostCard className="profile-video" post={post} data={user.posts} />
									</div>
								))
							) : (
								<p>Aucune publication trouvée.</p>
							)}
						</div>
					</div>
				)
			}

			{/* Projects Section */}
			{
				activeTab === "projects" && (
					<div className="projects-section">
						{/* Tabs Section */}
						<div className="profile-tabs">
							<button
								className={`tab-button ${activeTab === "posts" ? "active" : ""}`}
								onClick={() => handleTabChange("posts")}
							>
								Publications
							</button>
							<button
								className={`tab-button ${activeTab === "projects" ? "active" : ""}`}
								onClick={() => handleTabChange("projects")}
							>
								Projets
							</button>
						</div>
						<div className="search-bar">
							<FaSearch className="search-icon" />
							<input
								type="text"
								placeholder="Rechercher des projets..."
								value={searchProjectTerm}
								onChange={(e) => setSearchProjectTerm(e.target.value)}
							/>
						</div>
						<div className="projects-map-profile">
							{user.projects?.filter((project) => project.title?.toLowerCase().includes(searchProjectTerm.toLowerCase())).length > 0 ? (
								user.projects.filter((project) => project.title?.toLowerCase().includes(searchProjectTerm.toLowerCase())).map((project) => (
									<div className="projectCards-profile" key={project._id}>
										<div className="project-item">
											<h4>{project.title}</h4>
											<p>{project.description}</p>
										</div>
									</div>
								))
							) : (
								<p>Aucun projet trouvé.</p>
							)}
						</div>
					</div>
				)
			}

			{/* Modal for creating post */}
			{
				isCreatePostModalOpen && (
					<div className="modal">
						<div className="modal-upload-content">
							<CreatePost handleCloseCreatePostModal={handleCloseCreatePostModal} />
						</div>
					</div>
				)
			}
		</div >
	);
};

export default Profile;
