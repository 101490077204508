// webSocketActions.js
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const CONNECT_WEBSOCKET = 'CONNECT_WEBSOCKET';
export const DISCONNECT_WEBSOCKET = 'DISCONNECT_WEBSOCKET';
export const MESSAGE_READED = 'MESSAGE_READED';
export const READ_MESSAGE = 'READ_MESSAGE';
export const TYPING = 'TYPING';

export const sendMessage = (type, payload) => ({
	type: SEND_MESSAGE,
	payload: { type, payload },
});

export const readMessage = (type, payload) => ({
	type: READ_MESSAGE,
	payload: { type, payload },
});

// export const MessageReaded = (type, payload) => ({
// 	type: MESSAGE_READED,
// 	payload: payload,
// });

export const typingMessage = (type, payload) => ({
	type: TYPING,
	payload: payload,
});

export const receiveMessage = (message) => ({
	type: RECEIVE_MESSAGE,
	payload: message,
});

export const connectWebSocket = () => ({
	type: CONNECT_WEBSOCKET,
});

export const disconnectWebSocket = () => ({
	type: DISCONNECT_WEBSOCKET,
});
