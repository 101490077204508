import axios from "axios";

const MyAxios = axios.create({
	//production
	baseURL: "https://1le9dx9u8f.execute-api.us-east-1.amazonaws.com/",
	// baseURL: "https://www.christopherloisel.com/",
	// baseURL: "http://localhost:8080/",
});

MyAxios.interceptors.request.use((request) => {
	if (localStorage.getItem("token") !== null) {
		let token = localStorage.getItem("token");
		request.headers.Authorization = "Bearer " + localStorage.getItem("token");
	}
	return request;
});

MyAxios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			localStorage.removeItem("token");
		} else {
			return Promise.reject(error);
		}
	}
);

export default MyAxios;
