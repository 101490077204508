import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

const Footer = () => {
	return (
		<footer className="site-footer">
			<div className="footer-content">
				{/* Navigation links */}
				<div className="footer-section">
					<h3>Navigation</h3>
					<ul>
						<li><a href="/about">À propos</a></li>
						<li><a href="/contact">Contact</a></li>
						<li><a href="/faq">FAQ</a></li>
						<li><a href="/signup">Rejoins-nous</a></li>
					</ul>
				</div>

				{/* Social Media Links */}
				<div className="footer-section">
					<h3>Suivez-nous</h3>
					<div className="social-icons">
						<a href="https://facebook.com" aria-label="Facebook"><FaFacebookF /></a>
						<a href="https://twitter.com" aria-label="Twitter"><FaTwitter /></a>
						<a href="https://instagram.com" aria-label="Instagram"><FaInstagram /></a>
						<a href="https://youtube.com" aria-label="YouTube"><FaYoutube /></a>
					</div>
				</div>

				{/* Newsletter subscription */}
				<div className="footer-section">
					<h3>S'inscrire à la Newsletter</h3>
					<form className="newsletter-form">
						<input type="email" placeholder="Votre adresse email" required />
						<button type="submit">S'abonner</button>
					</form>
				</div>
			</div>

			{/* Footer Bottom Section */}
			<div className="footer-bottom">
				<p>&copy; 2024 Let’s Bee. Tous droits réservés.</p>
				<p><a href="/terms">Conditions d'utilisation</a> | <a href="/privacy">Politique de confidentialité</a></p>
			</div>
		</footer>
	);
};

export default Footer;
