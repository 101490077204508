import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MyAxios from "../Services/Interceptor";

export const signUp = createAsyncThunk(
	"auth/signUp",
	async (userData, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/auth/inscription", userData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const googleAuth = createAsyncThunk(
	"auth/googleAuth",
	async ({ token }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/auth/google-auth", { token });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const googleLogin = createAsyncThunk(
	"auth/googleLogin",
	async ({ token }, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/auth/google-login", { token });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const login = createAsyncThunk(
	"auth/login",
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/auth/connexion", credentials);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const resetPassword = createAsyncThunk(
	"auth/resetPassword",
	async (resetData, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/auth/reset-password", resetData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const refresh = createAsyncThunk(
	"auth/refresh",
	async (token, { rejectWithValue }) => {
		try {
			const response = await MyAxios.post("/auth/refresh", { token });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: null,
		token: null,
		status: "idle",
		error: null,
		userStatus: "idle",
		isAuthenticated: false,
	},
	reducers: {
		logout: (state) => {
			state.user = null;
			state.token = null;
			state.isAuthenticated = false;
			localStorage.removeItem("token");
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signUp.pending, (state) => {
				state.status = "loading";
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.user = action.payload.userId;
				state.token = action.payload.tokenAcces;
				state.isAuthenticated = true;
				localStorage.setItem("token", action.payload.tokenAcces);
			})
			.addCase(signUp.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload.error;
			})
			.addCase(login.pending, (state) => {
				state.status = "loading";
			})
			.addCase(login.fulfilled, (state, action) => {
				localStorage.setItem("token", action.payload.tokenAcces);
				state.status = "succeeded";
				state.user = action.payload.userId;
				state.token = action.payload.tokenAcces;
				state.isAuthenticated = true;
			})
			.addCase(login.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload.error;
			})
			.addCase(resetPassword.pending, (state) => {
				state.status = "loading";
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				state.status = "succeeded";
			})
			.addCase(resetPassword.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload.error;
			})
			.addCase(refresh.pending, (state) => {
				state.userStatus = "loading";
			})
			.addCase(refresh.fulfilled, (state, action) => {
				localStorage.setItem("token", action.payload.tokenAcces);
				// state.user.status = "succeeded";
				// state.user = action.payload.userId;
				state.isAuthenticated = true;
			})
			.addCase(refresh.rejected, (state) => {
				state.userStatus = "failed";
				state.isAuthenticated = false;
			})
			.addCase(googleAuth.pending, (state) => {
				state.status = "loading";
			})
			.addCase(googleAuth.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.user = action.payload.userId;
				state.token = action.payload.tokenAcces;
				state.isAuthenticated = true;
				localStorage.setItem("token", action.payload.tokenAcces);
			})
			.addCase(googleAuth.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload.error;
			})
			.addCase(googleLogin.pending, (state) => {
				state.status = "loading";
			})
			.addCase(googleLogin.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.user = action.payload.userId;
				state.token = action.payload.tokenAcces;
				state.isAuthenticated = true;
				localStorage.setItem("token", action.payload.tokenAcces);
			})
			.addCase(googleLogin.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload.error;
			});
	},
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
