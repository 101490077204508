import React from "react";
import "./CollaborationCard.css";
import { Link } from "react-router-dom";

const CollaborationCard = ({ collaboration }) => {
	return (
		<div className="collaboration-card">
			<div className="collaboration-header">
				<div className="collaboration-user-profile">
					<img
						src={collaboration.utilisateur_id.photo_profil}
						alt={`${collaboration.utilisateur_id.username} avatar`}
						className="user-avatar"
					/>
					<h4>{collaboration.utilisateur_id.username}</h4>
				</div>
			</div>

			<div className="collaboration-banner">
				<img src={collaboration.banner} alt={`${collaboration.titre} banner`} />
			</div>

			<div className="collaboration-details">
				<h3>{collaboration.titre}</h3>
				<p className="collaboration-description">{collaboration.description}</p>
				<p className="collaboration-category">Catégorie : {collaboration.categorie}</p>
				<p className="collaboration-dates">
					Du {new Date(collaboration.date_debut).toLocaleDateString()} au {new Date(collaboration.date_fin).toLocaleDateString()}
				</p>
			</div>

			<div className="collaboration-footer">
				<Link to={`/home/collaboration/${collaboration._id}`}>
					<button className="details-button">Plus de détails</button>
				</Link>
			</div>
		</div>
	);
};

export default CollaborationCard;
