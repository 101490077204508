import React, { useEffect, useState } from "react";
import "./Minibar.css";
import WebSocketComponent from "../WebSocketComponent.js/WebSocket";
import UserMiniature from "../../Components/UserMiniature/UserMiniature";
import { useDispatch, useSelector } from "react-redux";
import { LuMessageSquare } from "react-icons/lu";
import { FaBell } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import {
	fetchConversations,
	setCurrentConversation,
	fetchMessages,
} from "../../redux/messagesSlice";
import {
	fetchNotifications,
	markAsRead,
	deleteNotification,
	deleteAllNotifications,
} from "../../redux/notificationSlice";
import ChatWindow from "../../Components/ChatWindow/ChatWindow"; // Import the ChatWindow component

const Minibar = ({ onEvent }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user.user);
	const conversations = useSelector((state) => state.messages.conversations);
	const conversationMessages = useSelector((state) => state.messages.conversationMessages);
	const notifications = useSelector((state) => state.notifications.list);
	const [activeTab, setActiveTab] = useState("messages");
	const { msgOpen, setMsgOpen } = onEvent;
	const [openChats, setOpenChats] = useState([]);

	useEffect(() => {
		dispatch(fetchConversations());
		dispatch(fetchNotifications());
	}, [dispatch]);

	const handleConversationClick = (conversation) => {
		dispatch(setCurrentConversation(conversation));
		dispatch(fetchMessages(conversation._id));

		if (!openChats.some(chat => chat._id === conversation._id) && openChats.length < 3) {
			setOpenChats([...openChats, conversation]);
		}
	};

	const handleCloseChat = (conversationId) => {
		setOpenChats(openChats.filter(chat => chat._id !== conversationId));
	};

	const handleClick = () => {
		setActiveTab("messages");
		setMsgOpen(!msgOpen);
	};

	const handleNotificationClick = () => {
		setActiveTab("notifications");
		setMsgOpen(!msgOpen);
	};


	const unreadMessagesCount = conversations?.reduce((count, conversation) => {
		return count + conversation?.messages.filter(msg => !msg.read).length;
	}, 0);

	const unreadNotificationsCount = notifications.filter(notification => !notification.read).length;

	return (
		<div className="home-topbox">
			<div className="little-topbox">
				<WebSocketComponent />
				<div className="icon-container">
					<div className="icon-wrapper">
						<LuMessageSquare className="message-icon" onClick={handleClick} />
						{unreadMessagesCount > 0 && (
							<span className="badge">{unreadMessagesCount}</span>
						)}
					</div>
					<div className="icon-wrapper">
						<FaBell className="notification-icon" onClick={handleNotificationClick} />
						{unreadNotificationsCount > 0 && (
							<span className="badge">{unreadNotificationsCount}</span>
						)}
					</div>
					<UserMiniature />
				</div>
				{msgOpen && (
					<div className="msg-side-open">
						<div className="msg-side-header">
							<img
								src={user ? user.photo_profil : "https://wavefilesystem.s3.eu-west-3.amazonaws.com/images/user.png"}
								alt="User"
								className="roud-avatar"
							/>
							<h4 className="textbox">{
								user ? user.username : "Utilisateur"
							}</h4>
							<div className="fermer">
								<FaTimes
									onClick={handleClick}
								/>
							</div>
						</div>
						<div className="tabs">
							<button
								className={activeTab === "messages" ? "active" : ""}
								onClick={() => setActiveTab("messages")}
							>
								Messages
							</button>
							<button
								className={activeTab === "notifications" ? "active" : ""}
								onClick={() => setActiveTab("notifications")}
							>
								Notifications
							</button>
						</div>
						<div className="msg-input-container">
							{activeTab === "messages" ? (
								<>
									<input
										type="text"
										placeholder="Search a discussion"
									/>
									<button>Search</button>
								</>
							) : (
								<button onClick={() => dispatch(deleteAllNotifications())}>
									Supprimer toutes les notifications
								</button>
							)}
						</div>
						<div className="msg-side-content">
							{activeTab === "messages"
								? conversations.map((conversation) => {
									const lastMessage =
										conversation.messages.length > 0
											? conversation.messages[conversation.messages.length - 1].content
											: "No messages";

									return (
										<div
											key={conversation._id}
											className="menu-card"
											onClick={() => handleConversationClick(conversation)}
										>
											{
												conversation.otherUserPhoto ? (
													<img
														src={conversation.otherUserPhoto}
														alt="Conversation"
														className="roud-avatar"
													/>
												) : (
													<img
														src="https://wavefilesystem.s3.eu-west-3.amazonaws.com/images/user.png"
														alt="Conversation"
														className="roud-avatar"
													/>
												)
											}
											{
												conversation.users.length <= 2 && (
													<span
														className={`status-indicator ${conversation.isOnline ? 'online' : 'offline'}`}
													/>)
											}
											<div className="menu-messages-content">
												<h4>{conversation.name}</h4>
												<p>{lastMessage}</p>
											</div>
										</div>
									);
								})
								: notifications.map((notification) => (
									<div
										key={notification._id}
										className="notification-card"
										onClick={() => dispatch(deleteNotification(notification._id))}
										onMouseEnter={() => dispatch(markAsRead(notification.id))}
									>
										<p>{notification.title}</p>
										<p>{notification.content}</p>
										<p className="timestamp">{notification.timestamp}</p>
									</div>
								))}
						</div>
					</div>
				)}
			</div>
			{/* <div className=" chat-windows-container"> */}
			<div className={`${msgOpen ? "msg-open" : ""}`}>
				<div className={`chat-windows-container`}>
					{openChats.map((conversation) => (
						<ChatWindow
							key={conversation._id}
							conversationId={conversation._id}
							userName={conversation.name}
							userImage={conversation.otherUserPhoto || "https://wavefilesystem.s3.eu-west-3.amazonaws.com/images/user.png"}
							messages={conversationMessages[conversation._id]?.messages || []}
							onClose={() => handleCloseChat(conversation._id)}
						/>
					))}
				</div>
			</div>
		</div >
	);
};

export default Minibar;
