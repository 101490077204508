import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MyAxios from "../Services/Interceptor";
import axios from "axios";

export const profil = createAsyncThunk(
	"auth/profil",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/users/profil");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const SubscribeToUser = createAsyncThunk(
	"user/subscribe",
	async ({ targetUserId, notificationPreference }, { rejectWithValue }) => {
		try {
			// const response = await MyAxios.post(`/users/subaccount/`, {
			const token = localStorage.getItem("token");
			const response = await axios.post(`http://localhost:8080/users/subaccount/`,
				{
					_id: targetUserId,
					notificationPreference: "all",
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const LinkToUser = createAsyncThunk(
	"user/Link",
	async ({ targetUserId }, { rejectWithValue }) => {
		try {
			// const response = await MyAxios.post(`/users/linkaccount/`, {
			const token = localStorage.getItem("token");
			const response = await axios.post(`http://localhost:8080/users/linkaccount/`,
				{
					_id: targetUserId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);


export const fetchUserById = createAsyncThunk(
	"user/fetchUserById",
	async (userId, { rejectWithValue }) => {
		try {
			// const response = await MyAxios.get(`/users/profil/${userId}`);
			const token = localStorage.getItem("token");
			const response = await axios.get(`http://localhost:8080/users/profil/${userId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			console.log(response.data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchUserPublications = createAsyncThunk(
	"feed/fetchUserPublications",
	async (userId, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get(`/feed/publications/user/${userId}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchAllUsers = createAsyncThunk(
	"user/fetchAllUsers",
	async (_, { rejectWithValue }) => {
		try {
			const response = await MyAxios.get("/users/all");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchUserStats = createAsyncThunk(
	"user/fetchUserStats",
	async (_, { rejectWithValue, getState }) => {
		const { user } = getState().user.user;
		if (!user) {
			return rejectWithValue("No user logged in");
		}
		try {
			const response = await MyAxios.get(`/feed/user/${user._id}/stats`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const updateUserProfile = createAsyncThunk(
	"user/updateUserProfile",
	async (profileData, { rejectWithValue }) => {
		try {
			// let token = localStorage.getItem("token");

			// const response = await axios.put("http://localhost:8080/users/profil", profileData, {
			// 	headers: {
			// 		Authorization: `Bearer ${token}`,
			// 		"Content-Type": "multipart/form-data", // Indiquer qu'il s'agit de form-data
			// 	},
			// });
			const response = await MyAxios.put("/users/profil", profileData, {
				headers: {
					"Content-Type": "multipart/form-data", // Indiquer qu'il s'agit de form-data
				},
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);


const userSlice = createSlice({
	name: "user",
	initialState: {
		user: {
			user: null,
			status: "idle",
			error: null,
		},
		otherUser: {
			user: null,
			status: "idle",
			error: null,
		},
		stats: {
			stats: [],
			status: "idle",
			error: null,
		},
		editMode: false,
	},
	reducers: {
		toggleEditMode: (state) => {
			state.editMode = !state.editMode;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(profil.pending, (state) => {
				state.user.status = "loading";
			})
			.addCase(profil.fulfilled, (state, action) => {
				state.user.status = "succeeded";
				state.user.user = action.payload;
			})
			.addCase(profil.rejected, (state, action) => {
				state.user.user = "failed";
			})
			.addCase(fetchUserPublications.fulfilled, (state, action) => {
				state.user.user.posts = action.payload;
				console.log(action.payload);
			})
			.addCase(fetchUserPublications.rejected, (state, action) => {
				// state.postStatus = "failed";
				// state.error = action.payload;
			})
			.addCase(fetchUserById.pending, (state) => {
				state.otherUser.status = "loading";
			})
			.addCase(fetchUserById.fulfilled, (state, action) => {
				state.otherUser.status = "succeeded";
				state.otherUser.user = action.payload;
			})
			.addCase(fetchUserById.rejected, (state, action) => {
				state.otherUser.status = "failed";
				state.otherUser.error = action.payload.error;
			})
			.addCase(fetchUserStats.pending, (state) => {
				state.stats.status = "loading";
			})
			.addCase(fetchUserStats.fulfilled, (state, action) => {
				console.log("STATS", action.payload);
				state.stats.status = "succeeded";
				state.stats.stats = action.payload;
			})
			.addCase(fetchUserStats.rejected, (state, action) => {
				state.stats.status = "failed";
				state.stats.error = action.payload;
			})
			.addCase(updateUserProfile.pending, (state) => {
				state.user.status = "loading";
			})
			.addCase(updateUserProfile.fulfilled, (state, action) => {
				state.user.status = "succeeded";
				state.user.user = { ...state.user.user, ...action.payload.user };
			})
			.addCase(updateUserProfile.rejected, (state, action) => {
				state.userStatus = "failed";
				state.error = action.payload;
			})
			.addCase(fetchAllUsers.pending, (state) => {
				state.user.status = "loading";
			})
			.addCase(fetchAllUsers.fulfilled, (state, action) => {
				state.user.status = "succeeded";
				// state.user = action.payload;
			})
			.addCase(fetchAllUsers.rejected, (state, action) => {
				state.user.status = "failed";
				// state.error = action.payload;
			})
			.addCase(SubscribeToUser.pending, (state) => {
				state.user.status = "loading";
			})
			.addCase(SubscribeToUser.fulfilled, (state, action) => {
				state.user.status = "succeeded";
				state.otherUser.user.abonne = action.payload.abonne;
			})
			.addCase(SubscribeToUser.rejected, (state, action) => {
				state.user.status = "failed";
				state.user.error = action.payload;
			});
	},
});

export default userSlice.reducer;

export const { toggleEditMode } = userSlice.actions;
