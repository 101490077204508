import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = () => (
	<div className="loading-spinner">
		<div className="spinner"></div>
	</div>
);

export default LoadingSpinner;
